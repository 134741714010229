/**
 * About Banner
 */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

class AboutBanner extends Component {
  constructor() {
    super();
    this.state = { showText: false };
  }
  getMoreTextDiv() {
    if (this.state.showText) {
      return (
        <p>
          There are many variations of passages of Lorem Ipsum available, but
          the majority have suffered alteration in some form, by injected
          humour.
        </p>
      );
    } else {
      return null;
    }
  }
  render() {
    var expandedDiv = this.getMoreTextDiv();
    return (
      <Row className="section-ptb align-items-center">
        <Col md={6} className="order-md-1 mb-4 mb-md-0">
          <div id="toAbout" />
          <div className="single_image-wrapper">
            <img
              src={require(`../assets/images/categories/default/about-img.png`)}
              className="attachment-full img-fluid"
              alt
            />
          </div>
        </Col>
        <Col md={6}>
          <div className="text-wrapper">
            <span>Know more</span>
          </div>
          <div className="section-title mb-4">
            <h2 className="title text-left"> About {process.env.REACT_APP_NAME}</h2>
            <div className="text-wrapper">
              <p>
              {process.env.REACT_APP_NAME} Rade is where you can find everything you want in the field
                of gaming, from the latest PC games, PS4 games (soon PS5),
                XBOX-ONE games, NINTENDO SWITCH games and even STEAM games. On
                our site you will also find a variety of vouchers for all
                possible consoles.
              </p>
              {expandedDiv}
            </div>
          </div>
          <div className="ciyashop_button_wrapper ciyashop_button_width_default">
            <div className="inline_hover ciyashop_button_link ciyashop_button_size_medium button-underline">
              <Link
                Style="cursor:pointer;"
                className="inline_hover"
                onClick={() =>
                  this.setState({ showText: !this.state.showText })
                }
              >
                {' '}
                {this.state.showText ? 'Read Less..' : 'Read More..'}{' '}
              </Link>
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}

export default AboutBanner;
