/**
 *  Account Profile
 */
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import Common from '../../api/common';
import Sitebar from './Sitebar';
import {useSelector,shallowEqual} from 'react-redux'
import Login from '../Forms/Login'
import { useHistory } from "react-router-dom";

const AccountProfile = () => {

  let history = useHistory();
    useEffect(()=>{
      window.scrollTo(0, 0)
    },[])
    const Profile = useSelector(
      state => state.user.info ,
      shallowEqual
    )

    
    if(!Profile){
      return (
        <div>
          <div className="inner-intro">
              <Container>
                  <Row className="intro-title align-items-center">
                      <Col md={6} className="text-left">
                          <div className="intro-title-inner">
                          <h1>Login</h1>
                          </div>
                      </Col>
                      <Col md={6}  className="text-right">
                          <ul className="ciyashop_breadcrumbs page-breadcrumb breadcrumbs">
                          <li className="home">
                              <span>
                              <Link className="bread-link bread-home" to="/">Home</Link>
                              </span>
                          </li>
                          <li><span>Login</span></li>
                          </ul>
                      </Col>
                  </Row>
              </Container>
              </div>
         <div className="section-ptb">
           <Container>
            <Row className="align-items-center">
            <Col lg={4} className="mt-4 mt-lg-0">
                      <Login onFinish={()=>history.push("/Account/AccountProfile")}>

                      </Login>
                </Col>
            </Row>

           </Container>
          </div>
      </div>)
    }

      return (
           <div>
            <div className="inner-intro">
              <Container>
                  <Row className="intro-title align-items-center">
                      <Col md={6} className="text-left">
                          <div className="intro-title-inner">
                          <h1>My Account</h1>
                          </div>
                      </Col>
                      <Col md={6}  className="text-right">
                          <ul className="ciyashop_breadcrumbs page-breadcrumb breadcrumbs">
                          <li className="home">
                              <span>
                              <Link className="bread-link bread-home" to="/">Home</Link>
                              </span>
                          </li>
                          <li><span>My Account</span></li>
                          </ul>
                      </Col>
                  </Row>
              </Container>
              </div>
               <div className="section-ptb">
                <Container>
                <Row>
                  <Sitebar />
                  <Col lg={9} className="mt-4 mt-lg-0">
                    <Row>
                    <Col lg={12}>
                      <div className="woocommerce-Address">
                        <div className="woocommerce-Address-title">
                          <h5 class="mb-0">Profile Information</h5>
                          <Link className="edit" to="/Account/AccountProfileedit">Edit</Link>
                        </div>
                        <div className="woocommerce-Address-info mt-4">
                          <ul class="list-unstyled mb-0">
                            <li><span>First name:</span><strong>{Profile.firstName}</strong></li>
                            <li><span>Last name:</span><strong>{Profile.lastName}</strong></li>
                            <li><span>Gender:</span><strong>{Profile.gender}</strong></li>
                            <li><span>DOB:</span><strong>{(new Date(Profile.dob) != 'Invalid Date')&& new Date(Profile.dob).toLocaleDateString()}</strong></li>
                            <li><span>Phone no:</span><strong>{Profile.phone}</strong></li>
                            <li><span>Email:</span><strong>{Profile.email}</strong></li>
                            <li><span>Address:</span><strong>{Profile.address}</strong></li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                    </Row>
                  </Col>
                </Row>
                </Container>
            </div>
          </div>
        )
    
}
export default AccountProfile;
