/**
 *  Shop Checkout Page
 */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Input, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { usersActions } from '../../actions';
import PayPalBtn from '../../widgets/paypal';
import CreditCheckOutBtn from '../../widgets/praxiscashier';
import Loader from 'react-loader-spinner';

class CheckOut extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ShippingFlatRate: 0,
      ShippingLocalPickUp: 2.0,
      TotalShippingCarge: 0,
      fieldvalue: this.props.user?.info || {},
      errors: {},
      copState: false,
      inProses: false,
    };
    var removeFromCart, removeFromCartRe;
    //this.ReadShippingCharge = this.ReadShippingCharge.bind(this);
  }

  componentDidMount() {
    let cart = this.ReadCartItems();
    if (cart == null) {
      this.props.history.push(`/`);
    }
    this.removeFromCartRe(cart);
    var evt = document.createEvent('Event');
    evt.initEvent('load', false, false);
    window.dispatchEvent(evt);
    window.scrollTo(0, 0);
    this.forceUpdate();
    this.render();
  }

  removeFromCartRe(cart) {
    let index = cart.findIndex(({ ProductID }) => Number.isInteger(ProductID));
    if (index > -1) {
      this.removeFromCart(index);
      this.removeFromCartRe(this.ReadCartItems());
    }
  }
  paymentHandler = (details, data) => {
    //console.log(details, data);
    this.setState({ inProses: true });
    this.props
      .makeOrder(this.ReadCartItems(), data.orderID)
      .then((id) => {
        localStorage.removeItem('LocalCartItems');
        this.props.history.push(`/CouponInvoice?id=${id}`);
        this.setState({ inProses: false });
      })
      .catch(({data={}}={}) => {
        localStorage.removeItem('LocalCartItems');
        this.props.history.push(`/CouponInvoice?id=${data.id||0}`);
        this.setState({ inProses: false });
      });
  };

  ReadCartItems() {
    var cart = JSON.parse(localStorage.getItem('LocalCartItems'));
    if (cart == null) {
      this.props.history.push(`/`);
    }
    return cart;
  }
  onCheckOutSubmit(e) {
    e.preventDefault();
    if (this.handleValidation()) {
      const { user } = this.props;
      let isLogin = user.expiresAt && user.expiresAt > new Date().getTime() / 1000;

      if (!isLogin) {
        this.props
          .login(this.state.fieldvalue)
          .then(() => {})
          .catch((err) => {
            if (err?.statusCode == 244) {
              this.props
                .createUser(this.state.fieldvalue)
                .then(() => {})
                .catch((err) =>
                  this.setState({
                    errors: { api: 'Email allready in use / invalid Password' },
                  })
                );
            } else {
              this.setState({ errors: { api: 'unknown error' } });
            }
          });
      } else {
      }
    }
  }

  handleValidation() {
    let fieldvalue = this.state.fieldvalue;
    let errors = {};
    let formIsValid = true;

    const { user } = this.props;
    let isLogin = user.expiresAt && user.expiresAt > new Date().getTime() / 1000;

    if (!isLogin) {
      //First Name
      /*if (!fieldvalue['name']) {
        formIsValid = false;
        errors['name'] = 'Please Enter Name';
      }

      
      if (typeof fieldvalue['name'] !== 'undefined') {
        if (!fieldvalue['name'].match(/^[a-zA-Z]+$/)) {
          formIsValid = false;
          errors['name'] = 'Please Enter Only Letter';
        }
      }
      */

      //Email ID
      if (!fieldvalue['email']) {
        formIsValid = false;
        errors['email'] = 'Please Enter Email Address';
      }

      if (typeof fieldvalue['email'] !== 'undefined') {
        if (
          !fieldvalue['email'].match(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
        ) {
          formIsValid = false;
          errors['email'] = 'Please Enter Valid Email Address';
        }
      }

      if (!fieldvalue['password']) {
        formIsValid = false;
        errors['password'] = 'Please Enter Password';
      }

      if (typeof fieldvalue['password'] !== 'undefined') {
        if (fieldvalue['password'].length < 6) {
          formIsValid = false;
          errors['password'] = 'Password is too short, must be at least 6 characters';
        }
      }
    }
    //terms ID
    if (!fieldvalue['terms']) {
      formIsValid = false;
      errors['terms'] = 'Please Check terms';
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  handleChange(field, e) {
    let fieldvalue = this.state.fieldvalue;
    fieldvalue[field] = e.target.value;
    if (field == 'terms') fieldvalue[field] = e.target.checked;
    this.setState({ fieldvalue });
  }

  removeFromCart = (Index) => {
    var UpdatedCart = JSON.parse(localStorage.getItem('LocalCartItems'));
    UpdatedCart = UpdatedCart.slice(0, Index).concat(
      UpdatedCart.slice(Index + 1, UpdatedCart.length)
    );
    localStorage.removeItem('LocalCartItems');
    localStorage.setItem('LocalCartItems', JSON.stringify(UpdatedCart));
  };

  render() {
    const { user } = this.props;
    let isLogin = user.expiresAt && user.expiresAt > new Date().getTime() / 1000;
    let total = 0;
    let currency_code = '';
    try {
      total = parseFloat(
        parseFloat(
          this.ReadCartItems().reduce((fr, CartItem) => fr + CartItem.Qty * CartItem.Rate, 0)
        ) +
          parseFloat(
            this.state.TotalShippingCarge != undefined
              ? this.state.TotalShippingCarge.toFixed(2)
              : 0
          )
      )

      currency_code = this.ReadCartItems()[0]?.currency_code;
    } catch (err) {}

    return (
      <div class="site-content">
        <div className="inner-intro">
          <Container>
            <Row className="intro-title align-items-center">
              <Col md={6} className="text-left">
                <div className="intro-title-inner">
                  <h1>Checkout</h1>
                </div>
              </Col>
              <Col md={6} className="text-right">
                <ul className="ciyashop_breadcrumbs page-breadcrumb breadcrumbs">
                  <li className="home">
                    <span>
                      <Link className="bread-link bread-home" to="/">
                        Home
                      </Link>
                    </span>
                  </li>
                  <li>
                    <span>Checkout</span>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="content-wrapper mb-7">
          <Container>
            <form onSubmit={this.onCheckOutSubmit.bind(this)}>
              <Row class="mt-5">
                {this.state.makeOrderError ? (
                  <Col lg={6} className="mt-5">
                    <h3 id="order_review_heading">Something went wrong</h3>
                    <div id="order_review" class="checkout-review-order">
                      <h6>
                        <b> Please contact us on {process.env.REACT_APP_SUPPORT_EMAIL} with this details</b>
                      </h6>
                      <table class="shop_table checkout-review-order-table">
                        <tbody>
                          <tr class="cart_item">
                            <td class="product-name">Order ID</td>
                            <td class="product-name">{this.state.makeOrderError.orderID}</td>
                          </tr>
                          <tr class="cart_item">
                            <td class="product-name">Payer ID</td>
                            <td class="product-name">{this.state.makeOrderError.payerID}</td>
                          </tr>
                          <tr class="cart_item">
                            <td class="product-name">User ID</td>
                            <td class="product-name">{user.info.id}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </Col>
                ) : (
                  <>
                    {isLogin ? null : (
                      <Col class="col-lg-6">
                        <Row>
                          <Col sm={12}>
                            <div class="billing-fields mt-5">
                              <h3>Account details</h3>
                              <div class="billing-fields__field-wrapper">
                                {/*<div class="form-group">
                              <label for="name" class="">
                                First name&nbsp;
                                <abbr class="required" title="required">
                                  *
                                </abbr>
                              </label>
                              <Input
                                type="text"
                                class="form-control"
                                name="name"
                                id="name"
                                placeholder=""
                                value={this.state.fieldvalue.name}
                                onChange={this.handleChange.bind(this, 'name')}
                              />
                              <span className="error">{this.state.errors['name']}</span>
                </div>{' '}*/}
                                <div class="form-group">
                                  <label for="email" class="">
                                    Email&nbsp;
                                    <abbr class="required" title="required">
                                      *
                                    </abbr>
                                  </label>
                                  <Input
                                    type="text"
                                    class="form-control"
                                    name="email"
                                    id="email"
                                    placeholder=""
                                    value={this.state.fieldvalue.email}
                                    onChange={this.handleChange.bind(this, 'email')}
                                  />
                                  <span className="error">{this.state.errors['email']}</span>
                                </div>{' '}
                                <div class="form-group">
                                  <label for="password" class="">
                                    Password&nbsp;
                                    <abbr class="required" title="required">
                                      *
                                    </abbr>
                                  </label>
                                  <Input
                                    type="password"
                                    class="form-control"
                                    name="password"
                                    id="password"
                                    placeholder=""
                                    value={this.state.fieldvalue.password}
                                    onChange={this.handleChange.bind(this, 'password')}
                                  />
                                  <span className="error">{this.state.errors['password']}</span>
                                </div>{' '}
                                <div class="form-group">
                                  <label for="terms" class="">
                                    <Input
                                      type="checkbox"
                                      className="terms-check-box"
                                      name="terms"
                                      id="terms"
                                      onChange={this.handleChange.bind(this, 'terms')}
                                    />
                                    I have read and agree to the website terms&nbsp;
                                  </label>
                                  <span className="error">{this.state.errors['terms']}</span>
                                </div>{' '}
                                <div class="privacy-policy-text">
                                  <p>
                                    <span className="error-input-text">
                                      {this.state.errors['api']}
                                    </span>
                                  </p>
                                </div>
                              </div>{' '}
                              <button
                                type="submit"
                                class="checkout-login"
                                name="checkout_place_order"
                                id="place_order"
                                value="Place order"
                                data-value="Place order"
                              >
                                Log in / register
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    )}
                    <Col lg={6} className="mt-5">
                      <h3 id="order_review_heading">Your order</h3>

                      <div id="order_review" class="checkout-review-order">
                        {this.ReadCartItems() != null && this.ReadCartItems().length > 0 ? (
                          <table class="shop_table checkout-review-order-table">
                            <thead>
                              <tr>
                                <th class="product-name">Product</th>
                                <th class="product-total">Total</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.ReadCartItems().map((CartItem, index) => (
                                <tr class="cart_item">
                                  <td class="product-name">
                                    {CartItem.ProductName}&nbsp;{' '}
                                    <strong class="product-quantity">× {CartItem.Qty}</strong>{' '}
                                  </td>
                                  <td class="product-total">
                                    <span class="woocs_special_price_code">
                                      <span class="Price-amount amount">
                                        <span class="Price-currencySymbol">
                                          {CartItem.currency}
                                        </span>{' '}
                                        {(CartItem.price * CartItem.Qty).toLocaleString(
                                          navigator.language,
                                          {
                                            minimumFractionDigits: 0,
                                          }
                                        )}{' '}
                                      </span>
                                    </span>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                            <tfoot>
                              <tr class="cart-subtotal">
                                <th>Subtotal</th>
                                <td>
                                  <span class="woocs_special_price_code">
                                    <span class="Price-amount amount">
                                      <span class="Price-currencySymbol">
                                        {this.ReadCartItems()[0]?.currency}
                                      </span>
                                      {this.ReadCartItems()
                                        .reduce(
                                          (fr, CartItem) => fr + CartItem.Qty * CartItem.price,
                                          0
                                        )
                                        .toLocaleString(navigator.language, {
                                          minimumFractionDigits: 0,
                                        })}
                                    </span>
                                  </span>
                                </td>
                              </tr>
                              <tr class="cart-subtotal">
                                <th>Discount</th>
                                <td>
                                  <span class="woocs_special_price_code">
                                    <span class="Price-amount amount">
                                      <span class="Price-currencySymbol">
                                        {this.ReadCartItems()[0]?.currency}
                                      </span>
                                      {this.ReadCartItems()
                                        .reduce(
                                          (fr, CartItem) =>
                                            fr +
                                            CartItem.Qty * (CartItem.price * CartItem.discount),
                                          0
                                        )
                                        .toLocaleString(navigator.language, {
                                          minimumFractionDigits: 0,
                                        })}
                                    </span>
                                  </span>
                                </td>
                              </tr>

                              <tr class="order-total">
                                <th>Total</th>
                                <td>
                                  <strong>
                                    <span class="woocs_special_price_code">
                                      <span class="Price-amount amount">
                                        <span class="Price-currencySymbol">
                                          {this.ReadCartItems()[0]?.currency}
                                        </span>
                                        {total.toLocaleString(navigator.language, {
        minimumFractionDigits: 2,
      })}{' '}
                                      </span>
                                    </span>
                                  </strong>
                                </td>
                              </tr>
                            </tfoot>
                          </table>
                        ) : (
                          <div>No Items found</div>
                        )}
                        <div id="payment" className="checkout-payment">
                          {/*<ul class="payment_methods methods">
                        <li class="payment_method_paypal">
                          <label for="payment_method_paypal">
                            <img
                              src={require(`../../assets/images/payments.png`)}
                              className="img-fluid"
                              alt
                            />
                          </label>
                        </li>
                    </ul>*/}
                          <div class="form-row place-order">
                            <div class="terms-and-conditions-wrapper">
                              {/*<div class="privacy-policy-text">
                            <p>
                              Your personal data will be used to process your order, support your
                              experience throughout this website, and for other purposes described
                              in our
                            </p>
                  </div>

                          <p class="form-row validate-required">
                            <label class="form__label form__label-for-checkbox checkbox">
                              <Input
                                type="checkbox"
                                class="form__input form__input-checkbox input-checkbox"
                                name="terms"
                                id="terms"
                                onChange={this.handleChange.bind(this, 'terms')}
                              />
                              <span class="terms-and-conditions-checkbox-text">
                                <span className={this.state.errors['terms'] && 'error-input-text'}>
                                  I have read and agree to the website terms
                                </span>
                                &nbsp;<span class="required">*</span>
                              </span>

                              <span class="error">{}</span>
                            </label>
                            <Input type="hidden" name="terms-field" value="1" />
                          </p>
                          <div class="privacy-policy-text">
                            <p>
                              <span className="error-input-text">{this.state.errors['api']}</span>
                            </p>
                          </div>*/}
                              {this.state.inProses && (
                                <div className="m-2">
                                  <Loader type="TailSpin" color="#04d39f" height={80} width={80} />
                                </div>
                              )}
                            </div>
                            {/*}
                        <button
                          type="submit"
                          class="button alt"
                          name="checkout_place_order"
                          id="place_order"
                          value="Place order"
                          data-value="Place order"
                        >
                          {isLogin ? 'Place Order' : 'Log in / register and Place Order'}
                    </button>*/}
                          </div>
                        </div>
                        {this.ReadCartItems() != null && this.ReadCartItems().length > 0 && (
                          <div>
                            {/* <div className="paypay-btn">
                          <CreditCheckOutBtn
                            visible={isLogin}
                            amount={total}
                            currency={currency_code}
                            products={this.ReadCartItems()}
                            createSession={this.props.createSession}
                            onSuccess={this.paymentHandler}
                          />
                        </div> */}
                          <div className="paypay-btn">
                            <PayPalBtn
                              visible={isLogin}
                              amount={total}
                              currency={currency_code}
                              onSuccess={this.paymentHandler}
                            />
                          </div>
                          </div>
                        )}
                      </div>
                    </Col>
                  </>
                )}
              </Row>
            </form>
          </Container>
        </div>
      </div>
    );
  }
}

const StateToProps = (state) => {
  return {
    user: state.user,
  };
};

const DispatchToProps = (dispatch) => {
  return {
    createUser: async (user) => {
      await dispatch(usersActions.createUser(user));
    },
    login: async (user) => {
      await dispatch(usersActions.login(user));
    },
    makeOrder: async (products, tranID) => {
      return await dispatch(usersActions.makeOrder(products, tranID));
    },
    createSession: async (products,amount, currency) => {
      return await dispatch(usersActions.createSession(products, amount,currency));
    }
  };
};

export default connect(StateToProps, DispatchToProps)(CheckOut);
