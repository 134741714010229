/*
 * Admin Site Product Edit Page
 */
import React, { Component } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import ImageUploader from "react-images-upload";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { FormGroup, Input, Label, Row, Container, Button } from "reactstrap";
import { adminActions } from "../../actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};
const productslider = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
};
const productdata = {
  product_gallery: [
    "product-single.jpg",
    "product-single.jpg",
    "product-single.jpg",
    "product-single.jpg",
  ],
};

class ProductEditDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pictures: [],
      oldPictures: [],
      photoIndex: 0,
      isOpen: false,
      filedValue: {},
      error: {},
      ErrorMsg: "",
      isloading: false,
    };

    this.Uploadimage = this.Uploadimage.bind(this);
    this.Deleteimage = this.Deleteimage.bind(this);
  }

  Deleteimage(name) {
    const updatedOldPictures = this.state.oldPictures.filter(
      (pic) => pic != name
    );
    this.setState({ oldPictures: updatedOldPictures });
  }

  Uploadimage(picture) {
    if (picture == "") {
      this.setState({
        ...this.state,
        ErrorMsg: "File Not Supported",
      });
    } else {
      this.setState({
        pictures: this.state.pictures.concat(picture),
        ErrorMsg: "",
      });
    }
  }

  AddProductSubmit(e) {
    e.preventDefault();

    this.setState({ isloading: true, error: {} });
    this.props
      .updateProduct(
        this.state.productID,
        this.state.filedValue,
        this.state.pictures,
        this.state.oldPictures
      )
      .then(() => {
        if(this.props.isAdmin) this.props.history.push(`/admin-panel/Product`);
        else this.props.history.push(`/marchant-panel/Product`);
      })
      .catch((err) => {
        this.setState({ isloading: false });
        if (typeof err == "string") this.setState({ error: { api: err } });
        else console.log(err);
      });
    return true;
  }
  handleChange(filed, e) {
    let filedValue = this.state.filedValue;
    filedValue[filed] = e.target.value;
    this.setState({ filedValue });
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    const {
      id,
      name,
      currency,
      discount = 0,
      price,
      description = "",
      company,
      pictures,
    } = this.props.product;
    this.setState({
      filedValue: {
        name,
        currency: currency.code,
        discount: discount * 100,
        price,
        description,
        company: company.id,
      },
      oldPictures: pictures || [],
      productID: id,
    });
    if(this.props.isAdmin) this.props.getCompanies();
  }
  render() {
    return (
      <div>
        <div className="site-content">
          <div className="content-wrapper section-ptb">
            <Container>
              <div className="product-content-top single-product single-product-edit">
                <Row>
                  <div className="product-top-left col-xl-5 col-md-6">
                    <div className="product-top-left-inner">
                      <div className="ciyashop-product-images">
                        <div className="ciyashop-product-images-wrapper ciyashop-gallery-style-default ciyashop-gallery-thumb_position-bottom ciyashop-gallery-thumb_vh-horizontal">
                          {/*<div className="ciyashop-product-gallery ciyashop-product-gallery--with-images slick-carousel">
                                <Slider
                                  {...settings}
                                  className="ciyashop-product-gallery__wrapper popup-gallery"
                                >
                                  <div className="ciyashop-product-gallery__image">
                                    <img
                                      src={require(`../../../assets/images/${productdata.Product_single}`)}
                                      className="img-fluid"
                                    />
                                  </div>
                                </Slider>
                              </div>*/}
                          <div className="ciyashop-product-thumbnails">
                            <Slider
                              {...productslider}
                              className="ciyashop-product-thumbnails__wrapper"
                            >
                              {this.state.oldPictures.map((pic) => (
                                <div
                                  key={pic}
                                  className="ciyashop-product-thumbnail__image"
                                >
                                  <img
                                    src={`/api/v1/products/img/${pic}`}
                                    className="img-fluid"
                                  />
                                  <div className="d-flex justify-content-center image-content align-items-center">
                                    <span onClick={() => this.Deleteimage(pic)}>
                                      Click to delete
                                    </span>
                                  </div>
                                </div>
                              ))}
                              {productdata.product_gallery
                                .slice(
                                  0,
                                  this.state.oldPictures.length > 0
                                    ? -this.state.oldPictures.length
                                    : productdata.product_gallery.length
                                )
                                .map((pictureimage, index) => (
                                  <div key={index}>
                                    <div className="ciyashop-product-thumbnail__image">
                                      <a href="javascript:void(0)">
                                        <img
                                          src={require(`../../assets/images/${pictureimage}`)}
                                          className="img-fluid"
                                        />
                                      </a>
                                      <div className="d-flex justify-content-center image-content align-items-center">
                                        <ImageUploader
                                          buttonText=""
                                          withIcon={false}
                                          withPreview={true}
                                          fileTypeError={this.state.ErrorMsg}
                                          onChange={this.Uploadimage}
                                          imgExtension={[
                                            ".jpg",
                                            ".jpeg",
                                            ".png",
                                          ]}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                ))}
                            </Slider>
                          </div>
                          <div className="clearfix" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="product-top-right col-xl-7 col-md-6">
                    <div className="product-top-right-inner">
                      <div className="summary entry-summary">
                        <form onSubmit={this.AddProductSubmit.bind(this)}>
                          <FormGroup className="edit-icon">
                            <Input
                              type="text"
                              className="form-control product_title"
                              placeholder="Product Name"
                              required
                              name="name"
                              value={this.state.filedValue["name"]}
                              onChange={this.handleChange.bind(this, "name")}
                            />
                          </FormGroup>
                          <FormGroup className="edit-icon">
                            <Input
                              type="number"
                              className="form-control price"
                              placeholder="Product Price"
                              required
                              name="price"
                              value={this.state.filedValue["price"]}
                              onChange={this.handleChange.bind(this, "price")}
                            />
                          </FormGroup>
                          <Label className="title mb-2">Currency</Label>
                          <FormGroup>
                            {["EUR", "USD"].map((currency) => (
                              <Label>
                                <Input
                                  type="radio"
                                  name="currency"
                                  value={currency}
                                  required
                                  checked={
                                    this.state.filedValue["currency"] ===
                                    currency
                                  }
                                  onChange={this.handleChange.bind(
                                    this,
                                    "currency"
                                  )}
                                />{" "}
                                {currency}
                              </Label>
                            ))}
                          </FormGroup>
                          <FormGroup className="edit-icon">
                            <Input
                              type="number"
                              min="0"
                              max="100"
                              className="form-control"
                              placeholder="Discount  %"
                              name="discount"
                              value={this.state.filedValue["discount"]}
                              onChange={this.handleChange.bind(
                                this,
                                "discount"
                              )}
                            />
                          </FormGroup>
                          <FormGroup className="edit-icon">
                            <Input
                              type="textarea"
                              className="form-control"
                              rows="3"
                              placeholder="Product Description"
                              name="description"
                              value={this.state.filedValue["description"]}
                              onChange={this.handleChange.bind(
                                this,
                                "description"
                              )}
                            />
                          </FormGroup>
                          {/*<Label className="title">Size</Label>
                               <FormGroup>
                                                                {productdata.size.map((size) =>
                                                                    <Label>
                                                                        <Input type="checkbox"/>{' '}
                                                                        {size}
                                                                    </Label>
                                                                )}
                                                                </FormGroup>
                                                            <Label className="title">Color</Label>
                                                            <FormGroup>
                                                                {productdata.colors.map((color) =>
                                                                    <Label>
                                                                        <Input type="checkbox"/>{' '}
                                                                        {color}
                                                                    </Label>
                                                                )}
                                                            </FormGroup>*/}
                          {this.props.isAdmin && 
                          <><Label className="title mb-2">Company</Label>
                          <FormGroup>
                            {this.props.companies.map(({ companyName, id }) => (
                              <Label>
                                <Input
                                  type="radio"
                                  name="company"
                                  value={id}
                                  required
                                  checked={
                                    this.state.filedValue["company"] === id
                                  }
                                  onChange={this.handleChange.bind(
                                    this,
                                    "company"
                                  )}
                                />{" "}
                                {companyName}
                              </Label>
                            ))}
                          </FormGroup></>}
                          {/*<FormGroup>
                                                            <Label className="title pl-0">Product Stock</Label>
                                                            <input type="text" class="form-control" placeholder="Product Stock" ></input>
                                                            </FormGroup>
                                                            <FormGroup>
                                                            <Label className="title pl-0">Total Products</Label>
                                                            <input type="text" className="form-control" placeholder="Total Product" ></input>
                                                            </FormGroup>*/}
                          <span className="error">
                            {this.state.error["api"]}
                          </span>

                          <Button
                            type="submit"
                            className="action-button btn-primary"
                          >
                            {" "}
                            Save{" "}
                          </Button>
                        </form>
                        {this.state.isloading && (
                          <div className="lazyload-img"></div>
                        )}
                      </div>
                    </div>
                  </div>
                </Row>
              </div>
            </Container>
          </div>
        </div>
      </div>
    );
  }
}

const userStateToProps = (state) => {
  return {
    companies: state.companies.companies,
    isAdmin: state.user.isAdmin
  };
};

const userDispatchToProps = (dispatch) => {
  return {
    getCompanies: async () => {
      await dispatch(adminActions.getCompanies());
    },
    updateProduct: async (id, data, images, oldPictures) => {
      return await dispatch(
        adminActions.updateProduct(id, data, images, oldPictures)
      );
    },
  };
};

export default connect(
  userStateToProps,
  userDispatchToProps
)(withRouter(ProductEditDetail));
