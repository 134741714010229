/**
 *  Footer Main
 */
import React from "react";
import { Row, Col, Container } from "reactstrap";
import { Link } from "react-router-dom";

const logo =process.env.REACT_APP_LOGO
class Footer extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    window.addEventListener("scroll", this.Checkscroll);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.Checkscroll);
  }

  Checkscroll() {
    var scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;

    if (scrollTop > 350) {
      if (document.getElementById("back-to-top") != null) {
        document
          .getElementById("back-to-top")
          .setAttribute("style", "display:block");
      }
    } else {
      if (document.getElementById("back-to-top") != null) {
        document
          .getElementById("back-to-top")
          .setAttribute("style", "display:none");
      }
    }
  }
  ClicktoTop() {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }
  render() {
    let backtotop = { display: "none" };
    return (
      <div>
        <footer className="site-footer">
          <div className="footer-wrapper">
            <div className="footer-widgets-wrapper">
              <div className="footer">
                <Container>
                  <Row>
                    <div className="col-lg-5 col-md-6 footer-align-left">
                      <div className="logo-wrapper widget">
                        <p>
                          <Link to="#">
                            <img
                              className="img-fluid"
                              src={require(`../../assets/images/${logo}`)}
                              alt="logo"
                            />
                          </Link>
                        </p>
                      </div>
                      <div className="text-content">
                        <p className="mb-3 mt-4">
                          24/7/365 Delivery & Customer Support Our customer
                          support team is available 24 hours a day, 7 days a
                          week to assist all of our customers around the world.
                          Rest assured that any issue you experience will be
                          attended by an expert support representative, who will
                          do their best to solve any problem, and provide all
                          the information necessary to ensure a satisfying
                          customer experience. Please email us with any
                          questions or problems you are having. Be sure to check
                          our contact page for commonly asked questions.
                          {process.env.REACT_APP_SUPPORT_EMAIL}
                        </p>
                        <p className="mb-4">
                          The theme is packed with everything you need to create
                          a new website.
                        </p>
                      </div>
                      <div className="pgs-social-profiles mt-4">
                        <div className="social-profiles-wrapper">
                          <div className="social-profiles-wrapper-inner social-profiles-default social-profiles-shape-square">
                            <div className="social-profiles">
                              <ul>
                                <li>
                                  <a href="/" title="Facebook" target="_blank">
                                    <i className="fa fa-facebook" />
                                  </a>
                                </li>
                                <li>
                                  <a href="/" title="Twitter" target="_blank">
                                    <i className="fa fa-twitter" />
                                  </a>
                                </li>
                                <li>
                                  <a href="/" title="Google" target="_blank">
                                    <i className="fa fa-google" />
                                  </a>
                                </li>
                                <li>
                                  <a href="/" title="Vimeo" target="_blank">
                                    <i className="fa fa-vimeo" />
                                  </a>
                                </li>
                                <li>
                                  <a href="/" title="Pinterest" target="_blank">
                                    <i className="fa fa-pinterest" />
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 footer-align-left">
                      <div className="footer-nav-menu widget">
                        <h4 className="footer-title title">Useful Links</h4>
                        <div className="menu-useful-links-container">
                          <ul className="menu">
                            <li className="menu-item">
                              <Link to="/Terms">Terms & Conditions</Link>
                            </li>
                            <li className="menu-item">
                              <Link to="/shop">Shop</Link>
                            </li>
                            <li className="menu-item">
                              <Link to="/Contactus">Contact Us</Link>
                            </li>
                            <li className="menu-item">
                              <Link to="/RefundPolicy">
                                Cancellation & Refund Policy
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 footer-align-left">
                      <div className="pgs-contact-widget widget mt-4 mt-lg-0">
                        <h4 className="footer-title title">Contact Info</h4>
                        <div className="footer-address">
                          <ul>
                            <li>
                              <i className="fa fa-map-marker" />
                              <span>
                              {process.env.REACT_APP_ADDRESS}
                              </span>
                            </li>
                            <li className="pgs-contact-email">
                              <i className="fa fa-envelope-o" />
                              <span>{process.env.REACT_APP_SUPPORT_EMAIL}</span>
                            </li>
                            <li>
                              <i className="fa fa-phone" />
                              <span>{process.env.REACT_APP_PHONE}</span>
                            </li>
                          </ul>
                          <span>
                            <br />
                            Disclaimer: The businesses represented are not
                            affiliated or sponsors of Card Delivery. The logos
                            and identifying trademarks of each company are owned
                            by each company and/ or its affiliates.
                          </span>
                        </div>
                      </div>
                    </div>
                  </Row>
                </Container>
              </div>
            </div>
            <div className="site-info">
              <div className="footer-widget">
                <Container>
                  <div className="row align-items-center">
                    <Col md={6} className="float-left">
                      <p>
                        {" "}
                        © Copyright 2020 <Link to="#">{process.env.REACT_APP_COMPANY_NAME}</Link> All
                        Rights Reserved.
                      </p>
                    </Col>
                    <Col md={6} className="float-right">
                      <div className="payments text-right">
                        <img
                          src={require(`../../assets/images/payments.png`)}
                          className="img-fluid"
                          alt
                        />
                      </div>
                    </Col>
                  </div>
                  <div className="clearfix" />
                </Container>
              </div>
            </div>
          </div>
        </footer>
        {/* Back to Top */}
        <div id="back-to-top" style={backtotop} onClick={this.ClicktoTop}>
          <Link class="top arrow">
            <i class="fa fa-angle-up"></i>
          </Link>
        </div>
      </div>
    );
  }
}
export default Footer;
