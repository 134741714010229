import api from '../api';
export const SET_COUPONS = 'SET_COUPONS',
  SET_COMPANIES = 'SET_COMPANIES',
  SET_ORDERS = 'SET_ORDERS',
  SET_PRODUCTS = 'SET_PRODUCTS';

export const getCoupons = () => {
  return async (dispatch) => {
    const data = await api(`/coupon`);
    data.sort((a, b) => new Date(b.date) - new Date(a.date));
    dispatch({ type: SET_COUPONS, coupons: data });
  };
};

export const getOrders = () => {
  return async (dispatch) => {
    const data = await api(`/orders/all`);
    data.sort((a, b) => new Date(b.date) - new Date(a.date));
    dispatch({ type: SET_ORDERS, orders: data });
  };
};

export const getCompanies = () => {
  return async (dispatch) => {
    const data = await api(`/companies`);
    dispatch({ type: SET_COMPANIES, companies: data });
  };
};

export const createCompany = ({ password, email, name, companyName }) => {
  return async (dispatch) => {
    const token = await api(`/companies`, 'POST', {
      name: companyName,
      admin: { firstName: name, email, password },
    });

    dispatch(getCompanies());
    return token;
  };
};

export const updateCompany = (id, { email, name, companyName, allowedIPs }) => {
  return async (dispatch) => {
    const token = await api(`/companies/${id}`, 'PUT', {
      name: companyName,
      admin: { firstName: name, email },
      allowedIPs,
    });

    dispatch(getCompanies());
  };
};

export const createProduct = (filedValue = {}, images = []) => {
  return async (dispatch) => {
    const formData = new FormData();
    Object.entries(filedValue).forEach(([key, value]) => {
      formData.append(key, value);
    });
    images.forEach((file) => {
      formData.append('images', file);
    });

    const res = await api(`/products`, 'POST', formData, true);
    return;
  };
};

export const updateProduct = (id, filedValue = {}, images = [], oldImages = []) => {
  return async (dispatch) => {
    const formData = new FormData();
    Object.entries(filedValue).forEach(([key, value]) => {
      formData.append(key, value);
    });
    images.forEach((file) => {
      formData.append('images', file);
    });

    oldImages.forEach((img) => {
      formData.append('oldPictures', img);
    });

    const res = await api(`/products/${id}`, 'PUT', formData, true);
    return;
  };
};

export const getProducts = () => {
  return async (dispatch) => {
    const data = await api(`/products`);
    dispatch({ type: SET_PRODUCTS, products: data });
  };
};

export const getProduct = (id) => {
  return async (dispatch) => {
    const data = await api(`/products/${id}`);
    dispatch({ type: SET_PRODUCTS, products: [data] });
  };
};

export const getCompanyProducts = (id) => {
  return async (dispatch) => {
    const data = await api(`/products/company/${id}`);
    dispatch({ type: SET_PRODUCTS, products: data });
  };
};

export const deleteProduct = (id) => {
  return async (dispatch) => {
    const data = await api(`/products/${id}`, 'DELETE');
  };
};
