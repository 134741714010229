import { adminActions } from "../actions";

export default function companies(
  state = {
    companies: [],
  },
  action
) {
  switch (action.type) {
    case adminActions.SET_COMPANIES:
      return {
        ...state,
        companies: action.companies,
      };
    default:
      return state;
  }
}
