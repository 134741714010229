/**
 *  header-menu and sidebar menu data
 */
export default [
  {
    menu_title: 'Home',
    path: '/',
    icon: 'home',
    child_routes: null,
  },
  {
    menu_title: 'Shop',
    path: '/shop',
    mega: true,
    icon: 'party_mode',
    child_routes: null,
  },
  {
    menu_title: 'CONTACT US',
    path: '/contactus',
    mega: true,
    icon: 'party_mode',
    child_routes: null,
  } /*
   {
      "menu_title": "Blog",
      "path": "/Blogfullwidth",
      "mega": true,
      "icon": "party_mode",
      "type": "subMenu",
      "child_routes": [
         {
            "path": "/Blogfullwidth",
            "menu_title": "Blog Full Width",
            "icon": "arrow_right_alt",
            "child_routes": null
         },
         {
            "path": "/Blogsinglepage",
            "menu_title": "Blog Post Single",
            "icon": "arrow_right_alt",
            "child_routes": null
         },
      ]
   },

   {
      "menu_title": "Pages",
      "type": "subMenu",
      "path": "",
      "icon": "home",
      "child_routes": [
         {
            "path": "/Aboutus",
            "menu_title": "About Us",
            "icon": "arrow_right_alt",
            "child_routes": null
         },
         {
            "path": "/Contactus",
            "menu_title": "Contact Us",
            "icon": "arrow_right_alt",
            "child_routes": null
         },
         {
            "path": "/ComingSoon",
            "menu_title": "Coming Soon",
            "icon": "arrow_right_alt",
            "child_routes": null
         },
           {
            "path": "/Maintenance",
            "menu_title": "Maintenance",
            "icon": "arrow_right_alt",
            "child_routes": null
         },
         {
            "path": "/404",
            "menu_title": "Page Not Found",
            "icon": "arrow_right_alt",
            "child_routes": null
         }
      ]
   },
   {
      "menu_title": "Admin Panel",
      "path": "/admin-panel/Reports",
      "icon": "perm_identity",
      "child_routes": null
   }*/,
];
