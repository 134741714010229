import { batch } from 'react-redux';
import api from '../api';
export const SET_PROJECT = 'SET_PROJECT',
  SET_USER = 'SET_USER',
  SET_ORDER='SET_ORDER',
  LOGOUT = 'LOGOUT',
  SET_LOADING = 'SET_LOADING',
  LOGIN = 'LOGIN',
  SET_INFO = 'SET_INFO';

export const createUser = (user) => {
  return async (dispatch) => {
    const { email, password, name } = user;
    const { userInfo, expiresAt } = await api(`/auth/createUser`, 'POST', {
      firstName: name,
      email,
      password,
    });
    dispatch({ type: LOGIN, user: userInfo, expiresAt: expiresAt });
  };
};

export const login = (user) => {
  return async (dispatch) => {
    const { userInfo, expiresAt, isAdmin ,companyAdmin} = await api(`/auth/login`, 'POST', user);
    dispatch({ type: LOGIN, user: userInfo, expiresAt: expiresAt, isAdmin ,companyAdmin});
  };
};

export const editUser = (user) => {
  return async (dispatch) => {
    const userInfo = await api(`/users`, 'PUT', user);
    dispatch({ type: SET_INFO, info: userInfo });
  };
};

export const makeOrder = (products, tranID) => {
  return async (dispatch) => {
    const productsIDs = products.reduce((productsIDs, { ProductID, Qty }) => {
      for (let i = 0; i < Qty; i++) {
        productsIDs.push(ProductID);
      }
      return productsIDs;
    }, []);
    const id = await api(`/orders/paypal`, 'POST', { products: productsIDs, tranID });
    return id;
  };
};

export const createOrderPaypal = (products, tranID) => {
  return async (dispatch) => {
    const productsIDs = products.reduce((productsIDs, { ProductID, Qty }) => {
      for (let i = 0; i < Qty; i++) {
        productsIDs.push(ProductID);
      }
      return productsIDs;
    }, []);
    const id = await api(`/orders/paypal`, 'POST', { products: productsIDs, tranID });
    return id;
  };
};

export const createSession = (products,amount, currency ) => {
  return async () => {
    const productsIDs = products.reduce((productsIDs, { ProductID, Qty }) => {
      for (let i = 0; i < Qty; i++) {
        productsIDs.push(ProductID);
      }
      return productsIDs;
    }, []);
    const res = await api(`/orders/session`, 'POST', { amount, currency, products:productsIDs });
    console.log(res)
    return res;
  };
};

export const getOrder = (id) => {
  return async () => {
    return await api(`/orders/${id}`)
  };
};


export const getStripeCheckoutId = (id) => {
  return async () => {
    return await api(`/orders/stripe/${id}`)
  };
};


export const getGmacpeyCheckoutId = (id) => {
  return async () => {
    return await api(`/orders/gmacpey/${id}`)
  };
};


