/**
 *  Admin Site Product Add
 */
import React, { Component } from "react";
import ImageUploader from "react-images-upload";
import { connect } from "react-redux";
import Slider from "react-slick";
import { Container, FormGroup, Input, Label, Row, Button } from "reactstrap";
import { adminActions } from "../../../actions";

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};
const productslider = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
};
const productdata = {
  product_gallery: [
    "product-single.jpg",
    "product-single.jpg",
    "product-single.jpg",
    "product-single.jpg",
  ],
};
class Productadd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pictures: [],
      photoIndex: 0,
      filedValue: {},
      error: {},
      isOpen: false,
      ErrorMsg: "",
      isloading: false,
    };
    this.Uploadimage = this.Uploadimage.bind(this);
  }

  Uploadimage(picture) {
    if (picture == "") {
      this.setState({
        ...this.state,
        ErrorMsg: "File Not Supported",
      });
    } else {
      this.setState({
        pictures: this.state.pictures.concat(picture),
        ErrorMsg: "",
      });
    }
  }

  AddProductSubmit(e) {
    e.preventDefault();

    this.setState({ isloading: true, error: {} });
    this.props
      .createProduct(this.state.filedValue, this.state.pictures)
      .then(() => {
        this.props.history.push(`/marchant-panel/Product`);
      })
      .catch((err) => {
        this.setState({ isloading: false });
        if (typeof err == "string") this.setState({ error: { api: err } });
        else console.log(err);
      });
    return true;
  }
  handleChange(filed, e) {
    let filedValue = this.state.filedValue;
    filedValue[filed] = e.target.value;
    this.setState({ filedValue });
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div>
        <div className="site-content">
          <div className="content-wrapper section-ptb">
            <Container>
              <div className="product-content-top single-product single-product-edit">
                <Row>
                  <div className="product-top-left col-xl-5 col-md-6">
                    <div className="product-top-left-inner">
                      <div className="ciyashop-product-images">
                        <div className="ciyashop-product-images-wrapper ciyashop-gallery-style-default ciyashop-gallery-thumb_position-bottom ciyashop-gallery-thumb_vh-horizontal">
                          {/*<div className="ciyashop-product-gallery ciyashop-product-gallery--with-images slick-carousel">
                            <Slider
                              {...settings}
                              className="ciyashop-product-gallery__wrapper popup-gallery"
                            >
                              <div className="ciyashop-product-gallery__image">
                                <img
                                  src={require(`../../../assets/images/${productdata.Product_single}`)}
                                  className="img-fluid"
                                />
                              </div>
                            </Slider>
                          </div>*/}
                          <div className="ciyashop-product-thumbnails">
                            <Slider
                              {...productslider}
                              className="ciyashop-product-thumbnails__wrapper"
                            >
                              {productdata.product_gallery.map(
                                (pictureimage, index) => (
                                  <div key={index}>
                                    <div className="ciyashop-product-thumbnail__image">
                                      <a href="javascript:void(0)">
                                        <img
                                          src={require(`../../../assets/images/${pictureimage}`)}
                                          className="img-fluid"
                                        />
                                      </a>
                                      <div className="d-flex justify-content-center image-content align-items-center">
                                        <ImageUploader
                                          buttonText=""
                                          withIcon={false}
                                          withPreview={true}
                                          fileTypeError={this.state.ErrorMsg}
                                          onChange={this.Uploadimage}
                                          imgExtension={[
                                            ".jpg",
                                            ".jpeg",
                                            ".png",
                                          ]}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )
                              )}
                            </Slider>
                          </div>
                          <div className="clearfix" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="product-top-right col-xl-7 col-md-6">
                    <div className="product-top-right-inner">
                      <div className="summary entry-summary">
                        <form onSubmit={this.AddProductSubmit.bind(this)}>
                          <FormGroup className="edit-icon">
                            <Input
                              type="text"
                              className="form-control product_title"
                              placeholder="Product Name"
                              required
                              name="name"
                              value={this.state.filedValue["name"]}
                              onChange={this.handleChange.bind(this, "name")}
                            />
                          </FormGroup>
                          <FormGroup className="edit-icon">
                            <Input
                              type="number"
                              className="form-control price"
                              placeholder="Product Price"
                              required
                              name="price"
                              value={this.state.filedValue["price"]}
                              onChange={this.handleChange.bind(this, "price")}
                            />
                          </FormGroup>
                          <Label className="title mb-2">Currency</Label>
                          <FormGroup>
                            {["EUR", "USD"].map((currency) => (
                              <Label>
                                <Input
                                  type="radio"
                                  name="currency"
                                  value={currency}
                                  required
                                  checked={
                                    this.state.filedValue["currency"] ===
                                    currency
                                  }
                                  onChange={this.handleChange.bind(
                                    this,
                                    "currency"
                                  )}
                                />{" "}
                                {currency}
                              </Label>
                            ))}
                          </FormGroup>
                          <FormGroup className="edit-icon">
                            <Input
                              type="number"
                              min="0"
                              max="100"
                              className="form-control"
                              placeholder="Discount  %"
                              name="discount"
                              value={this.state.filedValue["discount"]}
                              onChange={this.handleChange.bind(
                                this,
                                "discount"
                              )}
                            />
                          </FormGroup>
                          <FormGroup className="edit-icon">
                            <Input
                              type="textarea"
                              className="form-control"
                              rows="3"
                              placeholder="Product Description"
                              name="description"
                              value={this.state.filedValue["description"]}
                              onChange={this.handleChange.bind(
                                this,
                                "description"
                              )}
                            />
                          </FormGroup>
                          {/*<Label className="title">Size</Label>
                           <FormGroup>
                                                            {productdata.size.map((size) =>
                                                                <Label>
                                                                    <Input type="checkbox"/>{' '}
                                                                    {size}
                                                                </Label>
                                                            )}
                                                            </FormGroup>
                                                        <Label className="title">Color</Label>
                                                        <FormGroup>
                                                            {productdata.colors.map((color) =>
                                                                <Label>
                                                                    <Input type="checkbox"/>{' '}
                                                                    {color}
                                                                </Label>
                                                            )}
                                                        </FormGroup>*/}

                          {/*<FormGroup>
                                                        <Label className="title pl-0">Product Stock</Label>
                                                        <input type="text" class="form-control" placeholder="Product Stock" ></input>
                                                        </FormGroup>
                                                        <FormGroup>
                                                        <Label className="title pl-0">Total Products</Label>
                                                        <input type="text" className="form-control" placeholder="Total Product" ></input>
                                                        </FormGroup>*/}
                          <span className="error">
                            {this.state.error["api"]}
                          </span>

                          <Button
                            type="submit"
                            className="action-button btn-primary"
                          >
                            {" "}
                            Add Product{" "}
                          </Button>
                        </form>
                        {this.state.isloading && (
                          <div className="lazyload-img"></div>
                        )}
                      </div>
                    </div>
                  </div>
                </Row>
              </div>
            </Container>
          </div>
        </div>
      </div>
    );
  }
}

const userStateToProps = (state) => {
  return {
    companies: state.companies.companies,
  };
};

const userDispatchToProps = (dispatch) => {
  return {
    createProduct: async (data, images) => {
      return await dispatch(adminActions.createProduct(data, images));
    },
  };
};

export default connect(userStateToProps, userDispatchToProps)(Productadd);
