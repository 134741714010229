/**
 *  About Us Page
 */
import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";
import AboutBanner2 from "../../widgets/AboutBanner2";
import BrandLogoSlider2 from "../../widgets/BrandLogoSlider2";
import InterestingFacts from "../../widgets/InterestingFacts";
import OurTeam from "../../widgets/OurTeam";
import PageTitle from "../../widgets/PageTitle";

const ourteam = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

class Index extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="site-content">
        <div className="inner-intro header_intro header_intro_bg-image header_intro_opacity header_intro_opacity-custom">
          <Container>
            <div className="row intro-title align-items-center intro-section-center">
              <PageTitle title={`Terms & Conditions`} />
            </div>
          </Container>
        </div>
        <div className="content-wrapper">
          <div className="section-wrapper section-ptb">
            <Container>
              <Row className="justify-content-center">
                <Col sm={12} lg={7}>
                  <div className="section-title">
                    <h3 className="title text-center">{`Terms & Conditions`}</h3>
                    <p className="">
                      By using any of our services or registering on the website
                      you agree that you have read, understood and are bound by
                      the Terms and Conditions, regardless of how you subscribe
                      to or use the services. If you do not want to be bound by
                      the Terms, you must not subscribe to or immediately
                      discontinue the use of our services. This document is an
                      electronic record in terms of Information Technology Act,
                      2020 and rules there under as applicable and the amended
                      provisions pertaining to electronic records in various
                      statutes as amended by the Information Technology Act,
                      2020. This electronic record is generated by a computer
                      system and does not require any physical or digital
                      signatures. For the purpose of these terms and conditions,
                      wherever the context so requires ‘You’ shall mean any
                      natural or legal person who has subscribed to or used the
                      services of this website or has agreed to become a member
                      of the Website by providing Registration Data while
                      registering on the Website as Registered User using the
                      computer systems. “{process.env.REACT_APP_COMPANY_NAME}/we/us/our/this website”
                      shall mean {process.env.REACT_APP_COMPANY_NAME}, a partnership firm based out of
                      Mumbai. Use of the {process.env.REACT_APP_COMPANY_NAME} website is available only
                      to persons who can form legally binding contracts under UK
                      Contract Act, 1872. Persons who are “incompetent to
                      contract” within the meaning of the UKn Contract Act, 1872
                      including minors, un-discharged insolvents etc. are not
                      eligible to use this Website. If you are a minor i.e.
                      under the age of 18 years, you shall not register as a
                      member of {process.env.REACT_APP_COMPANY_NAME} and shall not transact or use
                      {process.env.REACT_APP_COMPANY_NAME} website. As a minor if you wish to use or
                      transact on {process.env.REACT_APP_COMPANY_NAME}, such use or transaction may be
                      made by your legal guardian or parents who have registered
                      as users of the website. {process.env.REACT_APP_COMPANY_NAME} reserves the right
                      to terminate your membership and refuse to provide you
                      with access to the {process.env.REACT_APP_COMPANY_NAME} website if it is brought to
                      {process.env.REACT_APP_COMPANY_NAME}’s notice or if it is discovered that you are
                      under the age of 18 years.
                    </p>
                    <h3 className="title">Introduction:</h3>
                    <ol>
                      <li>
                      {process.env.REACT_APP_DOMAIN} (“Website”) is an Internet based
                        e-commerce portal operated by {process.env.REACT_APP_COMPANY_NAME}.
                      </li>
                      <li>
                        Use of the Website is offered to you conditioned on
                        acceptance without modification of all the terms,
                        conditions and notices contained in these Terms, as may
                        be posted on the Website from time to time.
                      </li>
                    </ol>
                    <h3 className="title">
                      User Account, Password, and Security:
                    </h3>
                    <p>
                      You are responsible for maintaining the confidentiality of
                      the password and account, and are fully responsible for
                      all activities that occur under your account. You agree to
                      immediately notify {process.env.REACT_APP_COMPANY_NAME} of any unauthorized use of
                      your password or account.
                    </p>
                    <h3 className="title">Limited User:</h3>
                    <p>
                      {process.env.REACT_APP_COMPANY_NAME} grants you a limited license to access and
                      make personal use of this site and not to download (other
                      than page caching) or modify it, or any portion of it,
                      except in case {process.env.REACT_APP_COMPANY_NAME} gives written consent for the
                      same. This license does not include any resale or
                      commercial use of this site or its contents; any
                      collection and use of any product listings, descriptions,
                      or prices; any derivative use of this site or its
                      contents; or any use of data mining, robots, or similar
                      data gathering and extraction tools. This site or any
                      portion of this site may not be reproduced, duplicated,
                      modified, copied, sold, resold, visited, or otherwise
                      exploited for any commercial purpose without express
                      written consent of {process.env.REACT_APP_COMPANY_NAME}. You shall not use any
                      meta tags or any other “hidden text” and {process.env.REACT_APP_COMPANY_NAME}’s
                      name or trademarks without the express written consent of
                      {process.env.REACT_APP_COMPANY_NAME}. Any unauthorized use terminates the
                      permission or license granted by {process.env.REACT_APP_COMPANY_NAME}. You are
                      granted a limited, revocable, and non-exclusive right to
                      create a hyperlink to the home page of {process.env.REACT_APP_COMPANY_NAME} as
                      long as the link does not portray {process.env.REACT_APP_COMPANY_NAME}, its
                      affiliates, or their products or services in a false,
                      misleading, derogatory, or otherwise offensive maNNer or
                      having a right to ownership of the same in any form. You
                      shall not use any {process.env.REACT_APP_COMPANY_NAME} logo or other proprietary
                      graphic or trademark as part of the link without express
                      written permission.
                    </p>
                    <h3 className="title">User Conduct and Rules:</h3>

                    <p>
                      You agree and undertake to use the Website and the Service
                      only to post and upload messages and material that are
                      proper. By way of example, and not as a limitation, you
                      agree and undertake that when using a Service, you will
                      not:
                    </p>
                    <ol>
                      <li>
                        {" "}
                        Defame, abuse, harass, stalk, threaten or otherwise
                        violate the legal rights of others;
                      </li>
                      <li>
                        {" "}
                        Publish, post, upload, distribute or disseminate any
                        inappropriate, profane, defamatory, infringing, obscene,
                        indecent or unlawful topic, name, material or
                        information;
                      </li>
                      <li>
                        {" "}
                        Upload files that contain software or other material
                        protected by intellectual property laws unless you own
                        or control the rights thereto or have received all
                        necessary consents;
                      </li>
                      <li>
                        {" "}
                        Upload any video, photographs, or images of another
                        person without his or her express written consent and
                        permission (In case of minors, even if you have consent
                        from the minor or his or her legal guardian).
                      </li>
                      <li>
                        {" "}
                        Upload or distribute files that contain viruses,
                        corrupted files, or any other similar software or
                        programs that may damage the operation of the Website or
                        another’s computer;
                      </li>
                      <li>
                        {" "}
                        Conduct or forward surveys, contests, pyramid schemes or
                        chain letters;
                      </li>
                      <li>
                        {" "}
                        Download any file posted by another user of a Service
                        that you know, or reasonably should know, cannot be
                        legally distributed in such manner;
                      </li>
                      <li>
                        {" "}
                        Falsify or delete any author attributions, legal or
                        other proper notices or proprietary designations or
                        labels of the origin or source of software or other
                        material contained in a file that is uploaded;
                      </li>
                      <li>
                        {" "}
                        Violate any code of conduct or other guidelines, which
                        may be applicable for or to any particular Service;
                      </li>
                      <li>
                        Violate any applicable laws or regulations for the time
                        being in force in or outside UK; and
                      </li>
                      <li>
                        Violate any of the terms and conditions of this
                        Agreement or any other terms and conditions for the use
                        of the Website contained elsewhere herein.
                      </li>
                      <li>
                        You shall not use any automatic device, program,
                        algorithm or methodology, or any similar or equivalent
                        manual process, to access, acquire, copy or monitor any
                        portion of the Website or any Content, or in any way
                        reproduce or circumvent the navigational structure or
                        presentation of the Website or any Content, to obtain or
                        attempt to obtain any materials, documents or
                        information through any means not purposely made
                        available through the Website. {process.env.REACT_APP_COMPANY_NAME} reserves the
                        right to bar any such activity.
                      </li>
                      <li>
                        You shall not create any false account or profile with
                        {process.env.REACT_APP_COMPANY_NAME}. You alone are solely responsible for the
                        data and information which has been provided to {process.env.REACT_APP_COMPANY_NAME}
                        LTD. You shall not impersonate any other
                        person/institution for any purposes.
                      </li>
                      <li>
                        {process.env.REACT_APP_COMPANY_NAME} reserves the right, but has no obligation,
                        to monitor the materials posted on{process.env.REACT_APP_COMPANY_NAME} website.
                        {process.env.REACT_APP_COMPANY_NAME} shall have the right to remove or edit any
                        Content that in its sole discretion violates, or is
                        alleged to violate, any applicable law or either the
                        spirit or letter of these Terms of Use. Notwithstanding
                        this right of {process.env.REACT_APP_COMPANY_NAME}, YOU REMAIN SOLELY
                        RESPONSIBLE FOR THE CONTENT OF THE MATERIALS YOU POST ON
                        THE WEBSITE AND IN YOUR PRIVATE MESSAGES. Please be
                        advised that such Content posted does not necessarily
                        reflect the views of {process.env.REACT_APP_COMPANY_NAME}. In no event shall
                        {process.env.REACT_APP_COMPANY_NAME} assume or have any responsibility or
                        liability for any Content posted or for any claims,
                        damages or losses resulting from use of Content and/or
                        appearance of Content on {process.env.REACT_APP_COMPANY_NAME}. You hereby
                        represent and warrant that you have all necessary rights
                        in and to all Content you provide and all information it
                        contains and that such Content shall not infringe any
                        proprietary or other rights of third parties or contain
                        any libelous, tortious, or otherwise unlawful
                        information.
                      </li>
                    </ol>
                    <h3 className="title">Pricing:</h3>
                    <p>
                      All prices are in UKn rupees. Prices, products and
                      Services may change at {process.env.REACT_APP_COMPANY_NAME} discretion.
                      Registration on the website is free. {process.env.REACT_APP_COMPANY_NAME} does not
                      charge any fee for browsing and buying. {process.env.REACT_APP_COMPANY_NAME} may
                      at its sole discretion introduce any new services and
                      modify some or all of the existing services offered.
                      Changes in context of the Fees and related policies shall
                      automatically become effective immediately once
                      implemented. You shall be solely responsible for
                      compliance of all applicable laws including those
                      applicable for UK for performing any transaction online.
                    </p>
                    <h3 className="title">Shipping:</h3>
                    <p>
                      To deliver your order as soon as possible we courier your
                      product(s) using some of the best couriers in UK. Orders
                      placed before 11:30AM, on a business day will be shipped
                      out the same day. All other orders will be shipped out the
                      next business day. After your order is shipped, transit
                      time typically 3 to 4 business days, with delivery
                      Monday-Saturday.
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    );
  }
}
export default Index;
