import axios from "axios";

const callApi = axios.create({
  baseURL: "/api/v1",
  timeout: 10000,
});

export default async function api(
  endpoint,
  method = "GET",
  body,
  fileToUpload = false
) {
  let headers = {};
  if (fileToUpload) headers["Content-Type"] = "multipart/form-data";
  const { status, data } = await callApi({
    method: method,
    url: endpoint,
    headers: headers,
    data: body,
  });
  switch (status) {
    case 200:
      return data;
    case 403:
      window.location.href = "/logout";
      return "error";
    case 203:
      window.location.href = "/logout";
      return "error";
    case 244:
      throw data;
  }
}
