/**
 *  Account Page Order History
 */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import Sitebar from "./Sitebar";
import api from "../../api";
import { CopyText } from "../../services/copy";

class OrderHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      isLoading:true,
      Order: [],
      ViewOrder: "",
    };
    this.toggle = this.toggle.bind(this);
  }
  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }
  onViewOrder(data) {
    this.setState({
      ...this.state,
      ViewOrder: data,
    });
    this.toggle();
  }
  componentDidMount() {
    this.setState({
      ...this.state,
      isLoading: true,
    })
    api("/orders")
      .then((orders) => this.setState({ Order: orders,isLoading:false }))
      .catch((err) => this.setState({ ...this.state,isLoading:false }));
    window.scrollTo(0, 0);
  }
  render() {
    const OrderHistory = this.state.Order;
    const { transactionID = null, products } = this.state.ViewOrder || {};
    return (
      <div>
        <div className="inner-intro">
          <Container>
            <Row className="intro-title align-items-center">
              <Col md={6} className="text-left">
                <div className="intro-title-inner">
                  <h1>My Account</h1>
                </div>
              </Col>
              <Col md={6} className="text-right">
                <ul className="ciyashop_breadcrumbs page-breadcrumb breadcrumbs">
                  <li className="home">
                    <span>
                      <Link className="bread-link bread-home" to="/">
                        Home
                      </Link>
                    </span>
                  </li>
                  <li>
                    <span>My Account</span>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="section-ptb">
          <Container>
            <Row>
              <Sitebar />
              {OrderHistory.length > 0 ? (
                <Col lg={9} className="mt-4 mt-lg-0">
                  <Row>
                    <Col lg={12}>
                      <div className="table-responsive">
                        <table class="table orderhistory-table mb-0">
                          <thead class="thead-light">
                            <tr>
                              <th scope="col">Order</th>
                              <th scope="col">Date</th>
                              <th scope="col">Total Paid</th>
                              <th scope="col">Action</th>
                            </tr>
                          </thead>
                          {OrderHistory !== null ? (
                            <tbody>
                              {OrderHistory.map(
                                (
                                  { date, products, id, transactionID: tranID },
                                  index
                                ) => (
                                  <tr>
                                    <td>{index + 1}</td>
                                    <td>
                                      {new Date(date).toLocaleString("en-GB")}
                                    </td>
                                    <td>
                                      {products.length > 0 ? (
                                        <span>
                                          {products[0].currency?.symbol}
                                          {products
                                            .reduce(
                                              (total, { salePrice }) =>
                                                total + salePrice,
                                              0
                                            )
                                            .toLocaleString(
                                              navigator.language,
                                              {
                                                minimumFractionDigits: 2,
                                              }
                                            )}
                                        </span>
                                      ) : (
                                        <span>0</span>
                                      )}
                                    </td>
                                    <td>
                                      <Link
                                        className="action-button"
                                        onClick={() =>
                                          this.onViewOrder({
                                            transactionID: tranID,
                                            products,
                                          })
                                        }
                                        href="#"
                                      >
                                        View
                                      </Link>
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          ) : null}
                        </table>
                      </div>
                    </Col>
                  </Row>
                  {/* modal-view */}
                  <Modal
                    isOpen={this.state.modal}
                    toggle={this.toggle}
                    className="modal-view modal-lg modal-dialog-centered"
                  >
                    <ModalHeader toggle={this.toggle}></ModalHeader>
                    {transactionID !== null ? (
                      <ModalBody>
                        <div className="success-screen">
                          <div className="thank-you text-center">
                            <h1 className="text-white">Order Details</h1>
                            <strong className="text-white">
                              Transaction ID:{transactionID}
                            </strong>
                          </div>
                          <div className="pt-4 px-4 pt-md-5 px-md-5 pb-3" />

                          <div className="ordered-detail">
                            <h5 className="mb-4">Coupons:</h5>

                            <div className="table-responsive">
                              <table class="table mb-0">
                                <th></th>
                                <th className="text-center">
                                  <h6 className="mb-0">Name</h6>
                                </th>
                                <th className="text-center">
                                  <h6 className="mb-0">Status</h6>
                                </th>
                                <th className="text-center">
                                  <h6 className="mb-0">Coupon Code</h6>
                                </th>
                                <th className="text-center">
                                  <h6 className="mb-0">Value</h6>
                                </th>
                                <tbody>
                                  {products.map(
                                    ({
                                      id,
                                      picture,
                                      name,
                                      currency,
                                      amount,
                                      isValid,
                                    }) => (
                                      <tr className="ordered-item">
                                        <td className="ordered-image">
                                          <img
                                            alt="img 01"
                                            src={`/api/v1/products/img/${picture}`}
                                            className="img-fluid"
                                          />
                                        </td>
                                        <td className="ordered-name">
                                          <span>{name}</span>
                                        </td>
                                        <td className="ordered-name">
                                          <span>
                                            {isValid ? "Valid" : "Activated"}
                                          </span>
                                        </td>
                                        <td className="ordered-name">
                                          <span>{id}</span>

                                          <h6 className="mt-0">
                                            <Link>
                                              <CopyText
                                                text={id}
                                                buttonText="Click here to copy"
                                              />
                                            </Link>
                                          </h6>
                                        </td>
                                        <td className="ordered-price">
                                          <span>
                                            {`${currency?.symbol} ${amount}`}
                                          </span>
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>
                            </div>
                            <div className="table-responsive">
                              <table class="table total-table table-borderless mt-4 mb-0">
                                <tbody>
                                  <tr>
                                    <td>Total Value</td>
                                    <td className="text-right">
                                      {products[0].currency?.symbol}
                                      {parseFloat(
                                        parseFloat(
                                          products.reduce(
                                            (fr, { price }) => fr + price,
                                            0
                                          )
                                        )
                                      ).toLocaleString(navigator.language, {
                                        minimumFractionDigits: 2,
                                      })}
                                    </td>
                                  </tr>
                                  {parseFloat(
                                    parseFloat(
                                      products.reduce(
                                        (fr, { discount }) => fr + discount,
                                        0
                                      )
                                    )
                                  ).toLocaleString(navigator.language, {
                                    minimumFractionDigits: 2,
                                  }) > 0 && (
                                    <tr>
                                      <td>Your profit</td>
                                      <td className="text-right">
                                        {products[0].currency?.symbol}
                                        {parseFloat(
                                          parseFloat(
                                            products.reduce(
                                              (fr, { discount }) =>
                                                fr + discount,
                                              0
                                            )
                                          )
                                        ).toLocaleString(navigator.language, {
                                          minimumFractionDigits: 2,
                                        })}
                                      </td>
                                    </tr>
                                  )}
                                  <tr className="border-top">
                                    <td>
                                      <strong className="h5">
                                        Total payment
                                      </strong>
                                    </td>
                                    <td className="text-right h5">
                                      <strong>
                                        {products[0].currency?.symbol}
                                        {parseFloat(
                                          parseFloat(
                                            products.reduce(
                                              (fr, { salePrice }) =>
                                                fr + salePrice,
                                              0
                                            )
                                          )
                                        ).toLocaleString(navigator.language, {
                                          minimumFractionDigits: 2,
                                        })}
                                      </strong>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </ModalBody>
                    ) : null}
                  </Modal>
                </Col>
              ) : (
                <Col lg={9} className="mt-4 mt-lg-0">
                                {this.state.isLoading ? (
                            <div className="lazyload-img"></div>
                          ):'No Order History'}
                  
                </Col>
              )}
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}
export default OrderHistory;
