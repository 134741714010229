import { adminActions,usersActions } from "../actions";
import AllProduct from "../api/product";

export default function coupons(
  state = {
    order:{},
    coupons: [],
    products: [],
  },
  action
) {
  switch (action.type) {
    case adminActions.SET_COUPONS:
      return {
        ...state,
        coupons: action.coupons,
      };

    case adminActions.SET_PRODUCTS:
      return {
        ...state,
        products: action.products,
      };    
    case usersActions.SET_ORDER:
      return {
        ...state,
        order: action.order,
      };
    default:
      return state;
  }
}
