/**
 *  Shop Cart Detail Page
 */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Popup from "reactjs-popup";
import { Col, Container, Row, Table } from "reactstrap";

class ShopingCart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ShippingFlatRate: 0,
      ShippingLocalPickUp: 2.0,
      TotalShippingCarge: 0,
      Adress1: "B-87,Erceru erpopnt",
      Adress2: "Arndurm",
      Adress3: "UK",
    };

    this.ReadCartItems = this.ReadCartItems.bind(this);
    this.PlusQty = this.PlusQty.bind(this);
    this.MinusQty = this.MinusQty.bind(this);
    var removeFromCart,
      removeFromCartRe,
      PlusQty,
      MinusQty,
      SetShippingCharge,
      SetShippingAddressChange;
  }

  componentDidMount() {
    let cart = this.ReadCartItems();
    if (cart == null) {
      this.props.history.push(`/`);
    }
    this.removeFromCartRe(cart);
    var evt = document.createEvent("Event");
    evt.initEvent("load", false, false);
    window.dispatchEvent(evt);
    window.scrollTo(0, 0);
    this.forceUpdate();
    this.render();
  }

  removeFromCartRe(cart) {
    let index = cart.findIndex(({ ProductID }) => Number.isInteger(ProductID));
    if (index > -1) {
      this.removeFromCart(index);
      this.removeFromCartRe(this.ReadCartItems());
    }
  }

  ReadCartItems() {
    return JSON.parse(localStorage.getItem("LocalCartItems"));
  }
  removeFromCart = (Index) => {
    var UpdatedCart = JSON.parse(localStorage.getItem("LocalCartItems"));
    UpdatedCart = UpdatedCart.slice(0, Index).concat(
      UpdatedCart.slice(Index + 1, UpdatedCart.length)
    );
    localStorage.removeItem("LocalCartItems");
    localStorage.setItem("LocalCartItems", JSON.stringify(UpdatedCart));
  };

  PlusQty = (Index) => {
    var UpdatedCart = JSON.parse(localStorage.getItem("LocalCartItems"));
    UpdatedCart[Index].Qty = parseInt(UpdatedCart[Index].Qty + 1);
    localStorage.removeItem("LocalCartItems");
    localStorage.setItem("LocalCartItems", JSON.stringify(UpdatedCart));
  };

  MinusQty = (Index) => {
    var UpdatedCart = JSON.parse(localStorage.getItem("LocalCartItems"));

    if (UpdatedCart[Index].Qty != 1) {
      UpdatedCart[Index].Qty = parseInt(UpdatedCart[Index].Qty - 1);
      localStorage.removeItem("LocalCartItems");
      localStorage.setItem("LocalCartItems", JSON.stringify(UpdatedCart));
    } else {
      this.removeFromCart(Index);
    }
  };

  render() {
    return (
      <div className="site-content">
        <div className="content-wrapper section-ptb">
          <Container>
            {this.ReadCartItems() != null && this.ReadCartItems().length > 0 ? (
              <Row>
                <Col xl={8}>
                  <div className="table-responsive">
                    <Table className="cart-table">
                      <thead>
                        <tr>
                          <th clas="product-remove"></th>
                          <th className="product-thumbnail"></th>
                          <th className="product-name">
                            <span className="nobr">Product</span>
                          </th>
                          <th className="product-price">
                            <span className="nobr">Price </span>
                          </th>
                          <th className="product-stock-status">Quantity</th>
                          <th className="product-subtotal">Total</th>
                        </tr>

                        {this.ReadCartItems().map((CartItem, index) => (
                          <tr>
                            <td className="product-remove">
                              <Link
                                onClick={() => this.removeFromCart(index)}
                                className="remove"
                              ></Link>
                            </td>
                            <td className="product-thumbnail">
                              <Link to="#">
                                <img
                                  src={
                                    Number.isInteger(CartItem.ProductID)
                                      ? require(`../../assets/images/${CartItem.ProductImage}`)
                                      : `/api/v1/products/img/${CartItem.ProductImage}`
                                  }
                                  alt="product"
                                />
                              </Link>
                            </td>
                            <td className="product-name">
                              {CartItem.ProductName}
                            </td>
                            <td className="product-price">
                              {CartItem.currency}
                              {CartItem.Rate.toLocaleString(
                                navigator.language,
                                { minimumFractionDigits: 0 }
                              )}
                            </td>
                            <td className="product-quantity">
                              <div className="quantity">
                                <label
                                  className="screen-reader-text"
                                  for="quantity_5cd96a418e8ad"
                                >
                                  Quantity
                                </label>
                                <input
                                  type="text"
                                  className="input-text qty text"
                                  value={CartItem.Qty}
                                  title="Qty"
                                />
                                <div className="quantity-nav">
                                  <Link
                                    className="quantity-button quantity-up"
                                    onClick={() => this.PlusQty(index)}
                                  >
                                    +
                                  </Link>
                                  <Link
                                    className="quantity-button quantity-down"
                                    onClick={() => this.MinusQty(index)}
                                  >
                                    -
                                  </Link>
                                </div>
                              </div>
                            </td>
                            <td className="product-subtotal">
                              {CartItem.currency}
                              {(CartItem.Rate * CartItem.Qty).toLocaleString(
                                navigator.language,
                                {
                                  minimumFractionDigits: 0,
                                }
                              )}
                            </td>
                          </tr>
                        ))}
                      </thead>
                    </Table>
                  </div>
                </Col>
                <div className="cart-collaterals col-xl-4">
                  <div className="cart_totals ">
                    <h2>Cart totals</h2>
                    <div className="table-responsive">
                      <Table
                        cellspacing="0"
                        className="shop_table shop_table_responsive"
                      >
                        <tbody>
                          <tr className="cart-subtotal">
                            <th>Subtotal</th>
                            <td data-title="Subtotal">
                              <span className="woocs_special_price_code">
                                <span className="Price-amount amount">
                                  <span className="Price-currencySymbol">
                                    {this.ReadCartItems()[0]?.currency}
                                  </span>{" "}
                                  {this.ReadCartItems()
                                    .reduce(
                                      (fr, CartItem) =>
                                        fr + CartItem.Qty * CartItem.Rate,
                                      0
                                    )
                                    .toLocaleString(navigator.language, {
                                      minimumFractionDigits: 0,
                                    })}{" "}
                                </span>
                              </span>
                            </td>
                          </tr>

                          <tr className="order-total">
                            <th>Total</th>
                            <td data-title="Total">
                              <strong>
                                <span className="special_price_code">
                                  <span className="Price-amount amount">
                                    <span className="Price-currencySymbol">
                                      {this.ReadCartItems()[0]?.currency}
                                    </span>{" "}
                                    {parseFloat(
                                      parseFloat(
                                        this.ReadCartItems().reduce(
                                          (fr, CartItem) =>
                                            fr + CartItem.Qty * CartItem.Rate,
                                          0
                                        )
                                      ) +
                                        parseFloat(
                                          this.state.TotalShippingCarge.toFixed(
                                            2
                                          )
                                        )
                                    ).toLocaleString(navigator.language, {
                                      minimumFractionDigits: 2,
                                    })}{" "}
                                  </span>
                                </span>
                              </strong>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                    <div className="proceed-to-checkout">
                      <Link to="CheckOut" className="checkout-button button">
                        Proceed to checkout
                      </Link>
                    </div>
                  </div>
                </div>
              </Row>
            ) : (
              <div className="wishlist-not-found">
                <img
                  src={require(`../../assets/images/empty-search.jpg`)}
                  className="img-fluid mb-4"
                />
                <h4 className="d-block">Your cart is currently empty.</h4>
                <a className="btn btn-primary" href="/shop">
                  Return to shop
                </a>
              </div>
            )}
          </Container>
        </div>
      </div>
    );
  }
}
export default ShopingCart;
