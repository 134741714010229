import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Button } from "reactstrap";
const CopyButton = ({ text, buttonText }) => {
  const [isCopied, setIsCopied] = useState(false);
  return (
    <>
      {!isCopied ? (
        <CopyToClipboard text={text} onCopy={() => {setIsCopied(true)
          setTimeout(()=>setIsCopied(false),2000)}}>
          <Button className="action-button btn-primary">{buttonText}</Button>
        </CopyToClipboard>
      ) : (
        <Button className="action-button btn-primary">Copied</Button>
      )}
    </>
  );
};

export const CopyText = ({ text, buttonText }) => {
  const [isCopied, setIsCopied] = useState(false);
  return (
    <>
      {!isCopied ? (
        <CopyToClipboard text={text} onCopy={() => {setIsCopied(true)
        setTimeout(()=>setIsCopied(false),2000)}
        }>
          <span style={{cursor:'pointer'}}>{buttonText}</span>
        </CopyToClipboard>
      ) : (
        <span>Copied</span>
      )}
    </>
  );
};

export default CopyButton;
