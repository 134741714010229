import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { usersActions } from "../../actions";

const Index = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch({ type: usersActions.LOGOUT });
    history.push("/");
  }, []);
  return <></>;
};
export default Index;
