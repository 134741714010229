/**
 *  Account Profile Edit
 */
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Container, Row } from 'reactstrap';
import Common from '../../api/common';
import Sitebar from './Sitebar';
import { connect } from 'react-redux';
import {usersActions} from '../../actions';
import { ToastContainer, toast } from 'react-toastify';

class AccountProfileedit extends Component {

  state = {
      fieldvalue:this.props.user,
      errors: {}
  };
  componentDidMount() {
      window.scrollTo(0, 0)
  }

  handleValidation()
  {
        let fieldvalue=this.state.fieldvalue;
        let errors = {};
        let formIsValid = true;

        //First Name
        if (!fieldvalue["firstName"]) {
            formIsValid = false;
            errors["firstName"] = "Please Enter First Name";
        }

        if (typeof fieldvalue["firstName"] !== "undefined") {
            if (!fieldvalue["firstName"].match(/^[a-zA-Z]+$/)) {
              formIsValid = false;
              errors["firstName"] = "Please Enter Only Letter";
            }
        }

        //Last Name
        if (!fieldvalue["lastName"]) {
            formIsValid = false;
            errors["lastName"] = "Please Enter Last Name";
        }

        if (typeof fieldvalue["lastName"] !== "undefined") {
            if (!fieldvalue["lastName"].match(/^[a-zA-Z]+$/)) {
              formIsValid = false;
              errors["lastName"] = "Please Enter Only Letter";
            }
        }

      //Male & Female Radio Button
      if (!fieldvalue["gender"]) {
          formIsValid = false;
          errors["gender"] = "Please Select Radio";
      }



      //Last Name
      if (!fieldvalue["phone"]) {
          formIsValid = false;
          errors["phone"] = "Please Enter Phone NO";
      }

      if (typeof fieldvalue["phone"] !== "undefined") {
          if (!fieldvalue["phone"].match(/^\d{10}$/)) {
            formIsValid = false;
            errors["phone"] = "Please Enter Valid Phone no";
          }
      }

      //Email ID
      if (!fieldvalue["email"]) {
        formIsValid = false;
        errors["email"] = "Please Enter Email";
      }

      if (typeof fieldvalue["emailid"] !== "undefined") {
          if (!fieldvalue["emailid"].match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\\.[A-Z]{2,6}$/)) {
            formIsValid = false;
            errors["emailid"] = "Please Enter Valid Email Address";
          }
      }

      //Address
      if (!fieldvalue["address"]) {
        formIsValid = false;
        errors["address"] = "Please Enter Address";
      }

      if (typeof fieldvalue["address"] !== "undefined") {
          if (!fieldvalue["address"].match(/^[a-zA-Z0-9 ]+$/)) {
            formIsValid = false;
            errors["address"] = "Please Enter Only Letter";
          }
      }

      this.setState({ errors: errors });
      return formIsValid;

  }

  onProfileFormSubmit(e){
    e.preventDefault();
    if(this.handleValidation()){
        console.log(this.state.fieldvalue,'this.state.fieldvalue;')
        this.props.editUser(this.state.fieldvalue)
            .then(()=>{
              toast.success("Edit Succeeded",{autoClose: 3000});
              this.props.history.goBack()
          })
          .catch(err => {
              const message = (err?.statusCode === 244) ? err.message : 'Internal error'
              toast.warning(message,{autoClose: 5000})
          })
        
    }
  }


  handleChange(field, e){
    let fieldvalue=this.state.fieldvalue;
    fieldvalue[field] = e.target.value;
    this.setState({fieldvalue});
  }

   render() {
    return (
          <div>
          <div className="inner-intro">
            <Container>
                <Row className="intro-title align-items-center">
                    <Col md={6} className="text-left">
                        <div className="intro-title-inner">
                        <h1>My Account</h1>
                        </div>
                    </Col>
                    <Col md={6}  className="text-right">
                        <ul className="ciyashop_breadcrumbs page-breadcrumb breadcrumbs">
                        <li className="home">
                            <span>
                            <Link className="bread-link bread-home" to="/">Home</Link>
                            </span>
                        </li>
                        <li><span>My Account</span></li>
                        </ul>
                    </Col>
                </Row>
            </Container>
            </div>
            <div className="section-ptb">
            <Container>
             <Row>
              <Sitebar />
              <Col lg={9} className="mt-4 mt-lg-0">
                <Row>
                <Col lg={12}>
                  <div className="woocommerce-Address woocommerce-Address-edit">
                    <div className="woocommerce-Address-title">
                      <h5 class="mb-0">Profile Information Edit </h5>
                    </div>
                    <div className="woocommerce-Address-info mt-4">
                    <form onSubmit={this.onProfileFormSubmit.bind(this)}>
                    <div class="form-group">
                          <label>First name</label>
                          <input type="text" class="form-control" value={this.state.fieldvalue.firstName}  onChange={this.handleChange.bind(this, "firstName")} placeholder="First name" />
                          <span className="error">{this.state.errors["firstName"]}</span>
                        </div>
                        <div class="form-group">
                          <label>Last name</label>
                          <input type="text" class="form-control"  value={this.state.fieldvalue.lastName}  onChange={this.handleChange.bind(this, "lastName")}  placeholder="Last name"></input>
                          <span className="error">{this.state.errors["lastName"]}</span>
                        </div>
                        <div class="form-group">
                        <label className="d-block">Gender</label>
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="male" name="gender" class="custom-control-input" value="Male" onChange={this.handleChange.bind(this, "gender")} checked={this.state.fieldvalue.gender === "Male"}></input>
                            <label class="custom-control-label" for="male" >Male</label>
                          </div>
                          <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" id="female" name="gender" class="custom-control-input" value="Female" onChange={this.handleChange.bind(this, "gender")} checked={this.state.fieldvalue.gender === "Female"}></input>
                            <label class="custom-control-label" for="female">Female</label>
                          </div>
                        </div>
                        <div class="form-group">
                          <label>Date of birth</label>
                          <input type="date" class="form-control" placeholder="Date of birth"  value={this.state.fieldvalue.dob} onChange={this.handleChange.bind(this, "dob")} ></input>
                        </div>
                        <div class="form-group">
                          <label>Phone</label>
                          <input type="text" class="form-control" placeholder="Phone" value={this.state.fieldvalue.phone} onChange={this.handleChange.bind(this, "phone")}></input>
                          <span className="error">{this.state.errors["phone"]}</span>
                        </div>
                        <div class="form-group">
                          <label>Email</label>
                          <input type="Email" class="form-control" placeholder="Email" value={this.state.fieldvalue.email} onChange={this.handleChange.bind(this, "email")}></input>
                          <span className="error">{this.state.errors["email"]}</span>
                        </div>
                        <div class="form-group">
                          <label>Address</label>
                          <textarea class="form-control" rows="3" placeholder="Address" value={this.state.fieldvalue.address}   onChange={this.handleChange.bind(this, "address")}></textarea>
                          <span className="error">{this.state.errors["address"]}</span>
                        </div>
                        <Button type="submit" className="btn btn-primary">Save</Button>
                    </form>
                    </div>
                  </div>
                </Col>
                </Row>
              </Col>
            </Row>
            </Container>
            </div>
            </div>
        )
    }
}

const StateToProps = state => {
  return {
    user: state.user.info
  };
};

const DispatchToProps = dispatch => {
  return {
    editUser: async (user) => {
      await dispatch(usersActions.editUser(user));
    }
  };
};


export default connect(StateToProps,DispatchToProps)(AccountProfileedit)
