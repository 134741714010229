/**
 * Combine Reducers Redux Data
 */
import { combineReducers } from "redux";
import { IntlReducer as ReducersIntl } from "react-redux-multilingual";

// Create Custome Reducers
import products from "./products";
import filters from "./filters";
import user from "./user";
import coupons from "./coupons";
import companies from "./companies";
import orders from "./orders";

export const rootReducer = combineReducers({
  data: products,
  filters: filters,
  user: user,
  coupons: coupons,
  companies: companies,
  orders:orders,
  ReducersIntl,
});
