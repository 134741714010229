/**
 *  Admin Panel Collaboration Bar
 */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import CopyBtn from "../../services/copy";
import AdminProfileDetail from "../../widgets/AdminProfileDetail";
import Adminsitebar from "./Adminsitebar";
import { adminActions } from "../../actions";
import { connect } from "react-redux";
import { ISO8601format } from "../../functions/date";

class Collaboration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      modal1: false,
      modal2: false,
      collaboration: [],
      filedValue: {},
      error: {},
      api: null,
      isloading: false,
      companyID: null,
    };

    this.toggle = this.toggle.bind(this);
    this.toggle1 = this.toggle1.bind(this);
    this.toggle2 = this.toggle2.bind(this);
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
      api: null,
      filedValue: {},
    }));
  }
  toggle1() {
    this.setState((prevState) => ({
      modal1: !prevState.modal1,
      filedValue: {},
    }));
  }
  toggle2(collaboration = {}) {
    let filedValue = {};
    if (this.state.modal2 === false) {
      const { companyName, email, name, allowedIPs = [] } = collaboration;
      filedValue = { companyName, email, name, allowedIPs };
    }
    this.setState((prevState) => ({
      modal2: !prevState.modal2,
      filedValue,
      companyID: collaboration.id,
    }));
  }

  onDeleteCollaboration(data) {
    this.data = data;
    this.toggle1();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.getCompanies().then(() => {
      this.setState({ collaboration: this.props.companies });
    });
  }

  onDeleteCollaborations(res) {
    if (res) {
      let deletedcollabration = this.data;
      let newdata = this.state.collaboration.filter(
        (CollaborationList) => CollaborationList.id !== deletedcollabration.id
      );
      this.setState({
        ...this.state,
        collaboration: newdata,
        modal1: false,
      });
    }
  }

  handleValidation() {
    let filedValue = this.state.filedValue;
    let errors = {};
    let formIsValid = true;

    //companyName
    if (!filedValue["companyName"]) {
      formIsValid = false;
      errors["companyName"] = "Please Enter Company Name";
    }

    if (typeof filedValue["companyName"] !== "undefined") {
      if (!filedValue["companyName"].match(/^[a-zA-Z ]+$/)) {
        formIsValid = false;
        errors["companyName"] = "Please Enter Valid Company Name";
      }
    }

    //User Name
    if (!filedValue["name"]) {
      formIsValid = false;
      errors["name"] = "Please Enter User Name";
    }

    if (typeof filedValue["name"] !== "undefined") {
      if (!filedValue["name"].match(/^[a-zA-Z ]+$/)) {
        formIsValid = false;
        errors["name"] = "Please Enter Valid User Name";
      }
    }

    //Email Name
    if (!filedValue["email"]) {
      formIsValid = false;
      errors["email"] = "Please Enter Email Address";
    }

    if (typeof filedValue["email"] !== "undefined") {
      if (
        !filedValue["email"].match(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
      ) {
        formIsValid = false;
        errors["email"] = "Please Enter Valid Email Address";
      }
    }

    if (!this.state.modal2) {
      //Password
      if (!filedValue["password"]) {
        formIsValid = false;
        errors["password"] = "Please Enter Password";
      }

      if (typeof filedValue["password"] !== "undefined") {
        if (filedValue["password"].length < 6) {
          formIsValid = false;
          errors["password"] =
            "Password is too short, must be at least 6 characters";
        }
      }
    }

    this.setState({ error: errors });
    return formIsValid;
  }

  AddUserSubmit(e) {
    e.preventDefault();
    if (this.handleValidation()) {
      this.setState({ isloading: true });
      this.props
        .createCompany(this.state.filedValue)
        .then((api) => this.setState({ api: api, isloading: false }))
        .catch((err) => {
          this.setState({ isloading: false });
          if (typeof err == "string") this.setState({ error: { api: err } });
          else console.log(err);
        });
      return true;
    }
  }

  EditUser(e) {
    e.preventDefault();
    if (this.handleValidation()) {
      this.setState({ isloading: true });
      this.props
        .updateCompany(this.state.companyID, this.state.filedValue)
        .then(() => {
          this.toggle2();
          this.setState({ isloading: false });
        })
        .catch((err) => {
          this.setState({ isloading: false });
          if (typeof err == "string") this.setState({ error: { api: err } });
          else console.log(err);
        });
      return true;
    }
  }

  handleChange(filed, e) {
    let filedValue = this.state.filedValue;
    filedValue[filed] = e.target.value;
    this.setState({ filedValue });
  }

  addIP() {
    let filedValue = this.state.filedValue;
    filedValue["allowedIPs"] = filedValue["allowedIPs"].concat(
      filedValue["ip"]
    );
    this.setState({ filedValue });
  }

  deleteIP(ip) {
    let filedValue = this.state.filedValue;
    filedValue["allowedIPs"] = this.state.filedValue["allowedIPs"].filter(
      (ips) => ip != ips
    );
    this.setState({ filedValue });
  }

  render() {
    const collabroations = this.props.companies;
    return (
      <div className="section-ptb">
        <Container>
          <Row>
            <Col lg={12}>
              <Row>
                <Col lg={12}>
                  <div className="woocommerce-Address">
                    <div className="woocommerce-Address-title">
                      <h5 class="mb-0 ">Merchants</h5>
                      <a
                        class="add-user btn btn-primary ml-auto"
                        onClick={this.toggle}
                        href="#"
                      >
                        Add Company
                      </a>
                    </div>
                    <div className="woocommerce-Address-info mt-4">
                      <div className="table-responsive">
                        <table class="table table-striped collaboration-table mb-0">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Email</th>
                              <th>Company</th>
                              <th>Coupons</th>
                              <th>Create At</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          {collabroations.length > 0 ? (
                            <tbody>
                              {collabroations.map((collabro) => (
                                <tr>
                                  <td>{collabro.name}</td>
                                  <td>{collabro.email}</td>
                                  <td>{collabro.companyName}</td>
                                  <td>
                                    <Link
                                      to={`/shopshop?comp=${collabro.id}`}
                                      className="view-button"
                                    >
                                      {" "}
                                      Coupons
                                    </Link>
                                  </td>
                                  <td>
                                    {new Date(
                                      collabro.createdAt
                                    ).toLocaleString("en-GB")}
                                  </td>
                                  <td>
                                    <Link
                                      className="view-button"
                                      onClick={() => this.toggle2(collabro)}
                                    >
                                      {" "}
                                      Edit
                                    </Link>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          ) : (
                            <tbody>
                              <tr>
                                <td colspan="6">
                                  <div class="no-data-found">
                                    No Data Available
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          )}
                        </table>
                      </div>
                    </div>
                  </div>

                  {/* modal add user */}
                  <Modal
                    isOpen={this.state.modal}
                    toggle={this.toggle}
                    className="modal-add-user modal-dialog-centered"
                  >
                    {this.state.api ? (
                      <>
                        <ModalBody>
                          <h5>API Key:</h5>
                          <textarea>{this.state.api.apiKey}</textarea>
                          <CopyBtn
                            text={this.state.api.apiKey}
                            buttonText="Copy"
                          />
                          <h5>Secret Key:</h5>
                          <textarea>{this.state.api.secretKey}</textarea>
                          <CopyBtn
                            text={this.state.api.secretKey}
                            buttonText="Copy"
                          />
                        </ModalBody>
                        <ModalFooter className="justify-content-start pt-4">

                          <Button
                            className="action-button no"
                            onClick={this.toggle}
                          >
                            I saved the api keys
                          </Button>
                        </ModalFooter>
                      </>
                    ) : (
                      <>
                        <ModalHeader toggle={this.toggle}></ModalHeader>
                        <form onSubmit={this.AddUserSubmit.bind(this)}>
                          <ModalBody>
                            <div class="form-group">
                              <label>Company Name</label>
                              <input
                                type="text"
                                class="form-control"
                                name="companyName"
                                value={this.state.filedValue["companyName"]}
                                onChange={this.handleChange.bind(
                                  this,
                                  "companyName"
                                )}
                                placeholder="Company Name"
                              ></input>
                              <span className="error">
                                {this.state.error["companyName"]}
                              </span>
                            </div>
                            <div class="form-group">
                              <label>First Name</label>
                              <input
                                type="text"
                                class="form-control"
                                name="name"
                                value={this.state.filedValue["name"]}
                                onChange={this.handleChange.bind(this, "name")}
                                placeholder="Name"
                              ></input>
                              <span className="error">
                                {this.state.error["name"]}
                              </span>
                            </div>
                            <div class="form-group">
                              <label>Email</label>
                              <input
                                type="text"
                                class="form-control"
                                name="email"
                                value={this.state.filedValue["email"]}
                                onChange={this.handleChange.bind(this, "email")}
                                placeholder="Email"
                              ></input>
                              <span className="error">
                                {this.state.error["email"]}
                              </span>
                            </div>
                            <div class="form-group">
                              <label>Password</label>
                              <input
                                type="password"
                                class="form-control"
                                name="password"
                                value={this.state.filedValue["password"]}
                                onChange={this.handleChange.bind(
                                  this,
                                  "password"
                                )}
                                placeholder="Password"
                              ></input>
                              <span className="error">
                                {this.state.error["password"]}
                              </span>
                            </div>
                            <span className="error">
                              {this.state.error["api"]}
                            </span>
                          </ModalBody>
                          {this.state.isloading && (
                            <div className="lazyload-img"></div>
                          )}
                          <ModalFooter className="justify-content-start pt-4">
                            <Button
                              type="submit"
                              className="action-button btn-primary"
                            >
                              Submit
                            </Button>
                            <Button
                              className="action-button no"
                              href="#"
                              onClick={this.toggle}
                            >
                              Cancel
                            </Button>
                          </ModalFooter>
                        </form>
                      </>
                    )}
                  </Modal>

                  {/* modal EDIT user */}
                  <Modal
                    isOpen={this.state.modal2}
                    toggle={this.toggle2}
                    className="modal-add-user modal-dialog-centered"
                  >
                    <ModalHeader toggle={this.toggle2}></ModalHeader>
                    <form onSubmit={this.EditUser.bind(this)}>
                      <ModalBody>
                        <div class="form-group">
                          <label>Company Name</label>
                          <input
                            type="text"
                            class="form-control"
                            name="companyName"
                            value={this.state.filedValue["companyName"]}
                            onChange={this.handleChange.bind(
                              this,
                              "companyName"
                            )}
                            placeholder="Company Name"
                          ></input>
                          <span className="error">
                            {this.state.error["companyName"]}
                          </span>
                        </div>
                        <div class="form-group">
                          <label>First Name</label>
                          <input
                            type="text"
                            class="form-control"
                            name="name"
                            value={this.state.filedValue["name"]}
                            onChange={this.handleChange.bind(this, "name")}
                            placeholder="Name"
                          ></input>
                          <span className="error">
                            {this.state.error["name"]}
                          </span>
                        </div>
                        <div class="form-group">
                          <label>Email</label>
                          <input
                            type="text"
                            class="form-control"
                            name="email"
                            value={this.state.filedValue["email"]}
                            onChange={this.handleChange.bind(this, "email")}
                            placeholder="Email"
                          ></input>
                          <span className="error">
                            {this.state.error["email"]}
                          </span>
                        </div>
                        <div class="form-group">
                          <label>Add ip</label>
                          <div>
                            <input
                              type="text"
                              class="form-control"
                              name="ip"
                              value={this.state.filedValue["ip"]}
                              onChange={this.handleChange.bind(this, "ip")}
                              placeholder="add ip"
                            ></input>
                            <Button
                              onClick={this.addIP.bind(this, "ip")}
                              className="action-button btn-primary"
                            >
                              add
                            </Button>
                          </div>
                          <span className="error">
                            {this.state.error["ip"]}
                          </span>
                        </div>
                        <div class="form-group">
                          <label>allowed IPs</label>
                          {this.state.filedValue["allowedIPs"]?.map((ip) => (
                            <div onClick={this.deleteIP.bind(this, ip)}>
                              {" "}
                              <label>{ip}</label>
                              <Link>
                                {" "}
                                <i className="fa fa-trash-o pl-2"></i>
                              </Link>
                            </div>
                          ))}
                        </div>
                        <span className="error">{this.state.error["api"]}</span>
                      </ModalBody>
                      {this.state.isloading && (
                        <div className="lazyload-img"></div>
                      )}
                      <ModalFooter className="justify-content-start pt-4">
                        <Button
                          type="submit"
                          className="action-button btn-primary"
                        >
                          Save
                        </Button>
                        <Button
                          className="action-button no"
                          href="#"
                          onClick={this.toggle2}
                        >
                          Cancel
                        </Button>
                      </ModalFooter>
                    </form>
                  </Modal>

                  {/* modal-delete */}
                  <Modal
                    isOpen={this.state.modal1}
                    toggle={this.toggle1}
                    className="modal-delete modal-lg modal-dialog-centered"
                  >
                    <ModalHeader toggle={this.toggle1}></ModalHeader>
                    <ModalBody>
                      Are You Sure You Want To Delete This User Permanently?
                    </ModalBody>
                    <ModalFooter className="justify-content-center pt-4">
                      <Link
                        className="action-button"
                        onClick={(res) => this.onDeleteCollaborations(res)}
                      >
                        Yes
                      </Link>
                      <Link className="action-button no" onClick={this.toggle1}>
                        No
                      </Link>
                    </ModalFooter>
                  </Modal>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const userStateToProps = (state) => {
  return {
    companies: state.companies.companies,
  };
};

const userDispatchToProps = (dispatch) => {
  return {
    getCompanies: async () => {
      await dispatch(adminActions.getCompanies());
    },
    createCompany: async (data) => {
      return await dispatch(adminActions.createCompany(data));
    },
    updateCompany: async (id, data) => {
      return await dispatch(adminActions.updateCompany(id, data));
    },
  };
};

export default connect(userStateToProps, userDispatchToProps)(Collaboration);
