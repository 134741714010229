/**
 *  Shop Cart Detail Page
 */
 import React, { Component } from 'react';
 import { withRouter } from "react-router-dom";
 import { connect } from "react-redux";
 import { Input, Container, Row, Table } from 'reactstrap';
 import { adminActions,usersActions } from "../../actions";
 import PayPalBtn from '../../widgets/paypal';
 import Loader from 'react-loader-spinner';
 
 class PayPalCheckout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            product:{},
            isLoading:true,
        };
    
        this.paymentHandler.bind(this)
      }
    
      componentDidMount() {

        const query = new URLSearchParams(this.props.location.search);
        const CurrentProductId = query.get("product");

        this.props.getProduct(CurrentProductId).then(()=>{
            const products = this.props.products
            if (products && products.length > 0) {
              products.map((product) => {
                if (product.id === CurrentProductId) {
                  this.setState({
                      ...this.state,
                    isLoading:false,
                    product,
                  });
                }
              });
            }
          }).catch((err) => console.log(err));  
      }

      paymentHandler = (details, data) => {
        this.setState({ isLoading: true });
        this.props
          .makeOrder([{ ProductID:this.state.product.id, Qty:1 }], data.orderID)
          .then((id) => {
            this.props.history.push(`/paypal/success?id=${id}`);
            this.setState({ isLoading: false });
          })
          .catch(({data={}}={}) => {
            this.props.history.push(`/paypal/success?id=${data.id||0}`);
            this.setState({ isLoading: false });
          });
      };

    
      render() {          
          if(this.state.isLoading) return (<div className="m-2">
              <Loader type="TailSpin" color="#04d39f" height={80} width={80} />
            </div> )
          else{
              const product = this.state.product
              
        return (
          <div className="site-content" >
            <div className="content-wrapper section-ptb">
              <Container>
                  <Row style={{justifyContent: 'center' }}>
                            <div className="cart-collaterals" style={{padding:'15px',display:'flex',borderRight: '1px solid #e8e8e8',marginRight:'20px',flexWrap: 'wrap' }}>
                                   <div style={{display: 'flex',justifyContent: 'center',alignItems: 'center'}}> {product.pictures&&<img
                                        style={{maxHeight:'300px',maxWidth:'250px'}}
                                      src={`/api/v1/products/img/${product.pictures[0]}`}
                                      alt="product"
                                    />}</div>
                                    <div style={{display: 'flex',justifyContent: 'center',padding: '10px',alignItems: 'center'}}>
                            <div>
                        <div className="">
                  <h1 className="product_title entry-title">{product.name}</h1>
                  <div className="product-details__short-description">
                    <div className="pdp-about-details-txt pdp-about-details-equit" style={{maxWidth:'200px'}}>
                      {product.description}
                      </div> </div> </div> </div>
                  </div>
</div>
                       
                      
                    
                    <div className="col-xl-4" style={{padding:'10px',maxWidth:'500px'}}>
                      <div className="cart_totals">
                        <h2>Pay with PayPal</h2>
                        <div className="table-responsive">
                          <Table
                            cellspacing="0"
                            className="shop_table shop_table_responsive"
                          >
                            <tbody>
                              <tr className="cart-subtotal">
                                <th>Price</th>
                                <td data-title="Subtotal">
                                  <span className="woocs_special_price_code">
                                    <span className="Price-amount amount">
                                      <span className="Price-currencySymbol">
                                        {product.currency?.symbol}
                                      </span>{" "}
                                      {product.price.toLocaleString(navigator.language, {
                                          minimumFractionDigits: 0,
                                        })}
                                    </span>
                                  </span>
                                </td>
                              </tr>

                              <tr className="cart-subtotal">
                                <th>Discount</th>
                                <td data-title="Subtotal">
                                  <span className="woocs_special_price_code">
                                    <span className="Price-amount amount">
                                      <span className="Price-currencySymbol">
                                      {product.currency?.symbol}
                                      </span>{" "}
                                      {(product.price * product.discount).toLocaleString(navigator.language, {
                                          minimumFractionDigits: 0,
                                        })}
                                    </span>
                                  </span>
                                </td>
                              </tr>
    
                              <tr className="order-total">
                                <th>Total</th>
                                <td data-title="Total">
                                  <strong>
                                    <span className="special_price_code">
                                      <span className="Price-amount amount">
                                        <span className="Price-currencySymbol">
                                        {product.currency?.symbol}
                                        </span>{product.salePrice.toLocaleString(navigator.language, {
                                          minimumFractionDigits: 0,
                                        })}
                                      </span>
                                    </span>
                                  </strong>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>

                                        
                            <PayPalBtn
                              visible={true}
                              amount={product.salePrice}
                              currency={product.currency.code}
                              onSuccess={this.paymentHandler}
                            />
                      </div></div>
                    
                  </Row>
              </Container>
            </div>
          </div>
        );}
      }
    }

 const AppMapStateToProps = (state) => { 
    return {
      products: state.coupons.products,
    };
  };
  const userDispatchToProps = (dispatch) => {
    return {
      getProduct: async (id) => {
        await dispatch(adminActions.getProduct(id));
      },
      makeOrder: async (products, tranID) => {
        return await dispatch(usersActions.createOrderPaypal(products, tranID));
      },
    };
  };
  
  export default connect(AppMapStateToProps,userDispatchToProps)(withRouter(PayPalCheckout));
 