/**
 *  Admin Invoive Page
 */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import ReactTable from "react-table";
import {
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import invoice from "../../api/invoice.js";
import { adminActions } from "../../actions";
import { connect } from "react-redux";
import { ISO8601format } from "../../functions/date";
import { CopyText } from "../../services/copy";
class Invoices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      modal1: false,
      dropdownOpen: false,
      isOpen: false,
      invoices: invoice,
      searchProduct: "",
      invoiceview: "",
    };

    this.toggle = this.toggle.bind(this);
    this.toggle1 = this.toggle1.bind(this);
  }
  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }
  toggle1() {
    this.setState((prevState) => ({
      modal1: !prevState.modal1,
    }));
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.getCoupons().then(() => {
      //console.log(this.props.coupons, "this.props.coupons");
      this.setState({ invoices: this.props.coupons });
    });
  }

  onSearchProduct(searchText) {
    if (searchText === "") {
      this.setState({
        ...this.state,
        invoices: this.props.coupons,
        searchProduct: searchText,
      });
    } else {
      let SearchBuyer = this.props.coupons.filter((invo) => {
        if (searchText === searchText.toLowerCase()) {
          let buyer =
            invo.email.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
            let coupon =
            invo.id.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
          return buyer || coupon;
        } else {
          let buyer =
            invo.email.toUpperCase().indexOf(searchText.toUpperCase()) > -1;
            let coupon =
            invo.id.toUpperCase().indexOf(searchText.toUpperCase()) > -1;
          return buyer || coupon; 
        }
      });
      this.setState({
        ...this.state,
        searchProduct: searchText,
        invoices: SearchBuyer,
      });
    }
  }
  onDeleteInvoicePopup(data) {
    this.data = data;
    this.toggle1();
  }
  onDeleteInvoice(res) {
    if (res) {
      let deleteInvoice = this.data;
      let newdata = this.state.invoices.filter(
        (InvoiceList) => InvoiceList.id !== deleteInvoice.id
      );
      this.setState({
        ...this.state,
        modal1: false,
        invoices: newdata,
      });
    }
  }

  onViewInvoicePopup(data) {
    this.setState({
      invoiceview: data,
    });
    this.toggle();
  }

  render() {
    const viewInvoice = this.state.invoiceview;
    const columns = [
      /*{
        maxWidth: 75,
        Header: "No.",
        accessor: "idd",
      },*/
      {
        sortable: false,
        Header: "Coupon",
        accessor: "id",
      },
      {
        minWidth: 160,
        Header: "Buyer",
        accessor: "email",
      },
      {
        Header: "Date",
        accessor: "date",
        Cell: (props) => {
          return (
            <div>
              {props.value ? new Date(props.value).toLocaleString("en-GB") : ""}
            </div>
          );
        },
      },
      {
        Header: "Status",
        accessor: "isValid",
        Cell: (props) => {
          return <div>{props.value ? "Valid" : "Activated"}</div>;
        },
      },
      /*{
        Header: "Payment",
        accessor: "payment",
      },*/
      {
        Header: "Amount",
        accessor: "amount",
        Cell: (props) => {
          return (
            <div>
              {props.original.currency?.symbol || ""}
              {props.value}
            </div>
          );
        },
      },
      {
        Header: "Company Name",
        accessor: "companyName",
      },
      {
        Header: "Activated At",
        accessor: "activatedAt",
      },
      {
        Header: "Is Demo",
        accessor: "isDemo",
        Cell: (props) => {
          return <div>{props.value ? "yes" : "no"}</div>;
        },
      },
      {
        Header: "Action",
        accessor: "action",
        maxWidth: 75,
        Cell: (props) => {
          return (
            <div>
              <Link
                className="view-button"
                onClick={() => this.onViewInvoicePopup(props.original)}
              >
                {" "}
                View <i className="fa fa-eye pl-2"></i>
              </Link>
              {/*<a
                className="delete-button"
                onClick={() => this.onDeleteInvoicePopup(props.original)}
              >
                Delete <i className="fa fa-trash-o pl-2"></i>
              </a>*/}
            </div>
          );
        },
      },
    ];

    return (
      <div>
        <div className="section-ptb">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="mb-0">
                  <h4>Coupons List</h4>
                </div>
                <div className="mb-4">
                  <form>
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Search"
                        value={this.state.searchProduct}
                        onChange={(e) => this.onSearchProduct(e.target.value)}
                      ></input>
                    </div>
                  </form>
                </div>
                <ReactTable
                  className="invoices-table"
                  data={this.state.invoices}
                  columns={columns}
                  minRows={1}
                  defaultPageSize={5}
                />
                {/* modal-view */}
                <Modal
                  isOpen={this.state.modal}
                  toggle={this.toggle}
                  className="modal-view modal-lg modal-dialog-centered"
                >
                  <ModalHeader toggle={this.toggle}></ModalHeader>
                  {viewInvoice !== null ? (
                    <ModalBody>
                      <div className="success-screen">
                        <div className="thank-you text-center">
                          <i className="fa fa-check-circle-o"></i>
                          <h1 className="text-white">Thank You</h1>
                          <span>Success! We received your payment.</span>
                          <strong className="text-white">
                            Transaction ID:{viewInvoice.invoiceid}
                          </strong>
                        </div>
                        <div className="pt-4 px-4 pt-md-5 px-md-5 pb-3" />
                        <div className="ordered-detail">
                          <div className="table-responsive">
                            <table class="table mb-0">
                              <th className="text-center">Status</th>
                              <th className="text-center">
                                <h6 className="mb-0">Coupon Code</h6>
                              </th>
                              <th className="text-center">
                                <h6 className="mb-0">Value</h6>
                              </th>
                              <tbody>
                                <tr className="ordered-item">
                                  <td className="ordered-image">
                                    {viewInvoice.isValid ? (
                                      "Valid"
                                    ) : (
                                      <span className="text-error">
                                        Activated
                                      </span>
                                    )}
                                  </td>
                                  <td className="ordered-name">
                                    <span>{viewInvoice.id}</span>

                                    <h6 className="mt-0">
                                      <Link>
                                        <CopyText
                                          text={viewInvoice.id}
                                          buttonText="Click here to copy"
                                        />
                                      </Link>
                                    </h6>
                                  </td>
                                  <td className="ordered-price">
                                    <span>
                                      {viewInvoice.currency?.symbol || ""}
                                      {viewInvoice.amount}
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </ModalBody>
                  ) : null}
                </Modal>

                {/* modal-delete 
                <Modal
                  isOpen={this.state.modal1}
                  toggle={this.toggle1}
                  className="modal-delete modal-lg modal-dialog-centered"
                >
                  <ModalHeader toggle={this.toggle1}></ModalHeader>
                  <ModalBody>
                    Are You Sure You Want To Delete This Invoice Permanently?
                  </ModalBody>
                  <ModalFooter className="justify-content-center pt-4">
                    <Link
                      className="action-button"
                      to="#"
                      onClick={(res) => this.onDeleteInvoice(res)}
                    >
                      Yes
                    </Link>
                    <Link
                      className="action-button no"
                      to="#"
                      onClick={this.toggle1}
                    >
                      No
                    </Link>
                  </ModalFooter>
                </Modal>*/}
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}
const userStateToProps = (state) => {
  return {
    coupons: state.coupons.coupons,
  };
};

const userDispatchToProps = (dispatch) => {
  return {
    getCoupons: async () => {
      await dispatch(adminActions.getCoupons());
    },
  };
};

export default connect(userStateToProps, userDispatchToProps)(Invoices);
