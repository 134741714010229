/**
 *  Admin Dashboard Menu
 */
import React, { Component, Fragment } from 'react';
import { Route, Redirect } from 'react-router-dom';
import PageNotFound from '../../component/Pages/PageNotFound';
import Footer from '../../layouts/footer/Footer';
import Headers from '../../layouts/header/Header';
import { connect } from 'react-redux';
import { usersActions } from '../../actions';

// Component
import AdminHeader from './Adminheader';
import AdminProductList from './AdminProductList';
import Collaboration from './Collaboration';
import Coupons from './Coupons';
import Orders from './Orders'
import Productadd from './Product/Productadd';
import Productedit from './Product/Productedit';
import Profile from './Profile';
import Profileedit from './Profileedit';
import Reports from './Reports';
import Settings from './Settings';
import Login from './Login';

class AdminDashboard extends Component {
  IsAdminPage(pathname) {
    let pathArray = pathname.split('/');
    let productEditPage = pathArray[pathArray.length - 2];
    let pageName = pathArray[pathArray.length - 1];
    if (productEditPage == 'Product-edit') {
      return true;
    } else {
      if (
        pageName == 'admin-panel' ||
        pageName == 'Reports' ||
        pageName == 'Coupons' ||
        pageName == 'Orders' ||
        pageName == 'Profile' ||
        pageName == 'Product' ||
        pageName == 'Profileedit' ||
        pageName == 'Profileedit' ||
        pageName == 'Collaboration' ||
        pageName == 'Settings' ||
        pageName == 'product-add' ||
        pageName == 'Product-edit'
      )
        return true;
      else return false;
    }
  }

  render() {
    const { location } = this.props;
    const { match } = this.props;
    const { user } = this.props;
    let isLogin = user.expiresAt && user.expiresAt > new Date().getTime() / 1000;

    return (
      <>
        {!isLogin ? (
          <Fragment>
            <Route path={`${match.url}`} component={Login} />
          </Fragment>
        ) : (
          <>
            {(user.isAdmin || user.isAdmin == 'true') && isLogin ? (
              <Fragment>
                {this.IsAdminPage(location.pathname) ? (
                  <AdminHeader>
                    <Route path={`${match.url}`} exact component={Coupons} />
                    <Route path={`${match.url}/Orders`} component={Orders} />
                    <Route path={`${match.url}/Reports`} component={Reports} />
                    <Route path={`${match.url}/Coupons`} component={Coupons} />
                    <Route path={`${match.url}/Profile`} component={Profile} />
                    <Route path={`${match.url}/Product`} component={AdminProductList} />
                    <Route path={`${match.url}/Profileedit`} component={Profileedit} />
                    <Route path={`${match.url}/Collaboration`} component={Collaboration} />
                    <Route path={`${match.url}/Settings`} component={Settings} />
                    <Route path={`${match.url}/product-add`} component={Productadd} />
                    <Route path={`${match.url}/Product-edit/:id`} component={Productedit} />
                  </AdminHeader>
                ) : (
                  <div>
                    <Headers />
                    <Route exact component={PageNotFound} />
                    <Footer />
                  </div>
                )}
              </Fragment>
            ) : (
              <Route exact component={PageNotFound} />
            )}
          </>
        )}
      </>
    );
  }
}

const userStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const userDispatchToProps = (dispatch) => {
  return {
    logout: () => {
      dispatch({ type: usersActions.LOGOUT });
    },
  };
};

export default connect(userStateToProps, userDispatchToProps)(AdminDashboard);
