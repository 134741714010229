

import { adminActions } from "../actions";

export default function orders(
  state = {
    orders:[]
  },
  action
) {
  switch (action.type) {
    case adminActions.SET_ORDERS:
      return {
        ...state,
        orders: action.orders,
      };
    default:
      return state;
  }
}
