/**
 *  About Us Page
 */
import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";
import AboutBanner2 from "../../widgets/AboutBanner2";
import BrandLogoSlider2 from "../../widgets/BrandLogoSlider2";
import InterestingFacts from "../../widgets/InterestingFacts";
import OurTeam from "../../widgets/OurTeam";
import PageTitle from "../../widgets/PageTitle";

const ourteam = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

class Index extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="site-content">
        <div className="inner-intro header_intro header_intro_bg-image header_intro_opacity header_intro_opacity-custom">
          <Container>
            <div className="row intro-title align-items-center intro-section-center">
              <PageTitle title={`Cancellation & Refund Policy`} />
            </div>
          </Container>
        </div>
        <div className="content-wrapper">
          <div className="section-wrapper section-ptb">
            <Container>
              <Row className="justify-content-center">
                <Col sm={12} lg={7}>
                  <div className="section-title">
                    <h3 className="title text-center">{`Cancellation & Refund Policy`}</h3>
                    <p className="">
                      Any request for cancellation of orders once dispatched
                      shall not be entertained. {process.env.REACT_APP_COMPANY_NAME} provides refund
                      only in case of
                      <ul>
                        <li> Damaged or Faulty Product(s)</li>
                        <li>
                          Wrong Product(s) delivered which are not as per your
                          order
                        </li>
                        <li> Cancellation of order before dispatch</li>
                      </ul>
                      All pre-owned games will have a GL symbol warranty stamp
                      on the game disc, refund/replacement will not be provided,
                      in case of any tamper with the stamp ink. A processing fee
                      of 2.5% excluding taxes will be charged on cancellation of
                      Digital Download orders before dispatch. This fee will be
                      waived off in case product cannot be dispatched due to a
                      fault of {process.env.REACT_APP_COMPANY_NAME} Please allow up to two weeks for
                      your refund to be processed. For orders placed through
                      gift certificates, refund would be provided in form of a
                      new gift certificate for the same value. We are unable to
                      refund digital download codes where regional compatibility
                      is the issue in question. To return products that are
                      damaged in transit or in case a wrong product is
                      delivered, please contact customer support by email on
                      {process.env.REACT_APP_SUPPORT_EMAIL} LTD.in within one working day of
                      delivery. Any return requests later than one working day
                      of delivery will not be entertained. Postage charges for
                      return of products will not be refunded. A processing fee
                      of 2.5% excluding taxes will be charged on cancellation of
                      prepaid orders before dispatch. This fee will be waived
                      off in case product cannot be dispatched due to a fault of
                      {process.env.REACT_APP_COMPANY_NAME} (example: out of stock products). Please
                      allow up to two weeks for your refund to be processed.
                      Please note that postage/courier charges are not
                      refundable. For items that were not delivered, refund will
                      be processed only on confirmation that the product was not
                      delivered to you and you choose to take a refund and are
                      not interested in any other product. For orders placed
                      through gift certificates, refund would be provided in
                      form of a new gift certificate for the same value.
                    </p>
                    <h3 className="title">
                      Cancellation and Refund Policy for PSN Digital Download
                      Code and PC DIgital Download Code :
                    </h3>

                    <p>
                      Orders of PC Digital Products and PSN Digital Products
                      cannot be cancelled or refunded for any reason. In the
                      unlikely event that your digital key is not working, we
                      will contact the key provider on your behalf. The key
                      provider will carry out an investigation and only if they
                      conclude that the key was actually not working, we will
                      issue a replacement key and the old key will get
                      blacklisted. This process will take a minimum of 21
                      working days. The decision of the key provider will be
                      final in any such cases.
                    </p>
                    <h3 className="title">Cancellation of order:</h3>
                    <p>
                      {process.env.REACT_APP_COMPANY_NAME} reserves the right to cancel any order in
                      bulk quantity/third party vendors without any explanation
                      for doing so, under situation where {process.env.REACT_APP_COMPANY_NAME} is not
                      able to meet the requirement of the order placed or order
                      so placed/cancelled does not comply with {process.env.REACT_APP_COMPANY_NAME}
                      policy. However we shall duly intimate the concerned
                      person and the applicable refund will be made in
                      reasonable time.
                    </p>
                    <h3 className="title">Trade In Policy For Games:</h3>
                    <ol>
                      <li>
                        {" "}
                        You can opt for our free pick up service or ship the
                        item to the address provided
                      </li>
                      <li>
                        {" "}
                        In case you ship the order to us, we will provide 12e
                        Cash/Credit extra per shipment
                      </li>
                      <li>
                        {" "}
                        Cash/Credit Prices given are valid for 15 days. Your
                        game copy must be shipped to us within that time to get
                        the quoted values.
                      </li>
                      <li>
                        {" "}
                        Disc based games can only be traded in their original
                        DVD sized cases
                      </li>
                      <li>
                        {" "}
                        Faulty items will not be accepted and will be returned
                        to the customer at their own expense or destroyed.
                      </li>
                      <li>
                        {" "}
                        Other damage or missing items will result in the
                        trade-in value being lowered accordingly
                      </li>
                      <li>
                        {" "}
                        {process.env.REACT_APP_COMPANY_NAME}.in decisions regarding trade-in game(s)
                        condition and subsequent reductions are final. Only
                        completely rejected items can be returned to sender (at
                        their expense),
                      </li>
                      <li>
                        {" "}
                        Only 1 copy of any title can be traded-in or cashed-in
                        by each customer/household.
                      </li>
                      <li> Trade-ins can only be accepted from UK</li>
                      <li>
                        {" "}
                        {process.env.REACT_APP_COMPANY_NAME}.in will not be responsible under any
                        circumstances for any loss or damage to the Item during
                        transit.
                      </li>
                      <li>
                        {" "}
                        Once we receive your game, it should only take a day or
                        two for it to be processed. (It depends on the time of
                        day it gets to us) An email will always be sent when a
                        Cash/Credit payment is processed and will include
                        details regarding when you can expect to receive it.
                      </li>
                      <li>
                        {" "}
                        To ensure that the funds get credited to the correct
                        account, details of the beneficiary’s branch IFSC (UKn
                        Financial System Code) and account number are to be
                        correctly provided. {process.env.REACT_APP_COMPANY_NAME} will not be responsible
                        for any wrong credits affected due to errors in IFSC and
                        the account number.
                      </li>
                      <li>
                        {" "}
                        Once store credit is issued, the value cannot be changed
                        to a cash refund. Any balance in your {process.env.REACT_APP_COMPANY_NAME}
                        wallet cannot be refunded in the form of cash in any
                        circumstance.
                      </li>
                    </ol>
                    <h3 className="title">
                      Trade In Policy For Consoles and Accessories:
                    </h3>
                    <ol>
                      <li>
                        {" "}
                        Cash/Credit Prices given are valid for 15 days. Your
                        product must be shipped to us within that time to get
                        the quoted values.
                      </li>
                      <li>
                        {" "}
                        When Trading-in any console/accessory it must be fully
                        working with the manufacturers guarantee security
                        seal(s) intact.
                      </li>
                      <li>
                        {" "}
                        All items that are included when bought new must be
                        present & fully functional (not paperwork but
                        controllers/TV cable/HDMI Cable etc).
                      </li>
                      <li>
                        {" "}
                        Faulty items will not be accepted and will be returned
                        to the customer at their own expense or destroyed.
                      </li>
                      <li>
                        {" "}
                        Other damage or missing items will result in the
                        trade-in value being lowered accordingly
                      </li>
                      <li>
                        {" "}
                        {process.env.REACT_APP_COMPANY_NAME}.in decision regarding trade-in console(s)
                        condition and subsequent reductions are final. Only
                        completely rejected items can be returned to sender (at
                        their expense)
                      </li>
                      <li>
                        {" "}
                        Only 1 console can be traded-in or cashed-in by each
                        customer/household.
                      </li>
                      <li> Trade-ins can only be accepted from UK</li>
                      <li>
                        {" "}
                        {process.env.REACT_APP_COMPANY_NAME}.in will not be responsible under any
                        circumstances for any loss or damage to the Item during
                        transit.
                      </li>
                      <li>
                        {" "}
                        Once we receive your console, it should take 3-4 days
                        for it to be processed. An email will always be sent
                        when a Cash/Credit payment is processed and will include
                        details regarding when you can expect to receive it.
                      </li>
                      <li>
                        {" "}
                        To ensure that the funds get credited to the correct
                        account, details of the beneficiary’s branch IFSC (UKn
                        Financial System Code) and account number are to be
                        correctly provided. {process.env.REACT_APP_COMPANY_NAME} will not be responsible
                        for any wrong credits affected due to errors in IFSC and
                        the account number.
                      </li>
                      <li>
                        {" "}
                        Once store credit is issued, the value cannot be changed
                        to a cash refund.
                      </li>
                      <li>
                        {" "}
                        Any balance in your {process.env.REACT_APP_COMPANY_NAME} wallet cannot be
                        refunded in the form of cash in any circumstance.
                      </li>
                    </ol>

                    <h3 className="title">Restrictions on use, Terms of use</h3>
                    <p>
                      Except where specifically authorised, the User may not
                      modify, copy, reproduce, republish, upload, post, transmit
                      or distribute in any way any material from this site
                      including code and software. By using the {process.env.REACT_APP_COMPANY_NAME}
                      Site (other than to read this Agreement for the first
                      time), User agrees to comply with all of the terms and
                      conditions hereof. The right to use the {process.env.REACT_APP_COMPANY_NAME} Site
                      is personal to User and is not transferable to any other
                      person or entity. User is responsible for all use of
                      User’s Account (under any screen name or password) and for
                      ensuring that all use of User’s Account complies fully
                      with the provisions of this Agreement. User shall be
                      responsible for protecting the confidentiality of User’s
                      password(s), if any. {process.env.REACT_APP_COMPANY_NAME} shall have the right at
                      any time to change or discontinue any aspect or feature of
                      {process.env.REACT_APP_COMPANY_NAME} Site, including, but not limited to, content,
                      hours of availability and equipment needed for access or
                      use.
                    </p>
                    <h3 className="title">Email</h3>
                    <p>
                      Communicating through E-mail is not secure and capable of
                      interception, corruption and delays. Anyone communicating
                      with {process.env.REACT_APP_COMPANY_NAME} by email accepts the risks involved and
                      their consequences. Before opening any attachment/(s)
                      please check them for viruses and defects.
                    </p>
                    <h3 className="title">Changed Terms</h3>
                    <p>
                      {process.env.REACT_APP_COMPANY_NAME} shall have the right at any time to change or
                      modify the terms and conditions applicable to your use of
                      the {process.env.REACT_APP_COMPANY_NAME} Site, or any part thereof, or to impose
                      new conditions, including but not limited to, adding fees
                      and charges for use. Such changes, modifications,
                      additions or deletions shall be effective immediately upon
                      notice thereof, which may be given by means including but
                      not limited to, posting on the {process.env.REACT_APP_COMPANY_NAME} Site, or by
                      electronic or conventional mail, or by any other means by
                      which User obtains notice thereof. Any use of the {process.env.REACT_APP_COMPANY_NAME}
                      LTD Site by User after such notice shall be deemed to
                      constitute acceptance by User of such changes,
                      modifications or additions.
                    </p>
                    <h3 className="title">Equipment</h3>
                    <p>
                      User shall be responsible for obtaining and maintaining
                      all telephone, computer hardware and other equipment
                      needed for access to and use of the {process.env.REACT_APP_COMPANY_NAME} Site and
                      all charges related thereto.
                    </p>
                    <h3 className="title">Your Conduct</h3>
                    <p>
                      You shall use the {process.env.REACT_APP_COMPANY_NAME} Site for lawful purposes
                      only. You shall not post or transmit through the {process.env.REACT_APP_COMPANY_NAME}
                      LTD Site any material which violates or infringes in any
                      way upon the rights of others, which is unlawful,
                      threatening, abusive, defamatory, invasive of privacy or
                      publicity rights, vulgar, obscene, profane or otherwise
                      objectionable, which encourages conduct that would
                      constitute a criminal offence, give right to civil
                      liability or otherwise violate any law, or which, without
                      the {process.env.REACT_APP_COMPANY_NAME}’s express prior approval, contains
                      advertising or any solicitation with respect to products
                      or services. Any conduct by you that in {process.env.REACT_APP_COMPANY_NAME}’s
                      discretion restricts or inhibits any other user from using
                      or enjoying {process.env.REACT_APP_COMPANY_NAME}’s Site will not be permitted.
                      User shall not use {process.env.REACT_APP_COMPANY_NAME}’s Site to advertise or
                      perform any commercial solicitation, including, but not
                      limited to, the solicitation of users to become
                      subscribers of other on-line information services
                      competitive with the {process.env.REACT_APP_COMPANY_NAME} Site. You may not
                      modify, publish, transmit, participate in the transfer or
                      sale, create derivative works, or in any way exploit, any
                      of the content, in whole or in part. User may download
                      copyrighted material for User’s personal use only. Except
                      as otherwise expressly permitted under copyright law, no
                      copying, redistribution, retransmission, publication or
                      commercial exploitation of downloaded material will be
                      permitted without the express permission of {process.env.REACT_APP_COMPANY_NAME}
                      and the copyright owner. In the event of any permitted
                      copying, redistribution or publication of copyrighted
                      material, no changes in or deletion of author attribution,
                      trademark legend or copyright notice shall be made. User
                      acknowledges that it does not acquire any ownership rights
                      by downloading copyrighted material.
                    </p>
                    <p>
                      User shall not upload, post or otherwise make available on
                      the {process.env.REACT_APP_COMPANY_NAME} Site any material protected by copyright,
                      trademark or other proprietary right without the express
                      permission of the owner of the copyright, trademark or
                      other proprietary right and the burden of determining that
                      any material is not protected by copyright rests with
                      User. User shall be solely liable for any damage resulting
                      from any infringement of copyrights, proprietary rights,
                      or any other harm resulting from such a submission. By
                      submitting material to any public area of {process.env.REACT_APP_COMPANY_NAME}
                      Site, User automatically grants, or warrants that the
                      owner of such material has expressly granted {process.env.REACT_APP_COMPANY_NAME}
                      the royalty fee, perpetual, irrevocable, non-exclusive
                      right and license to use, reproduce, modify, adapt,
                      publish, translate and distribute such material (in whole
                      or in part) worldwide and/or to incorporate it in other
                      works in any form, media or technology now known or
                      hereafter developed for the full term of any copyright
                      that may exist in such material. User also permits any
                      other User to access, view, store or reproduce the
                      material for that User’s personal use. User hereby grants
                      {process.env.REACT_APP_COMPANY_NAME} the right to edit, copy, publish and
                      distribute any material made available on {process.env.REACT_APP_COMPANY_NAME}
                      Site by User.
                    </p>
                    <p>
                      The foregoing provisions are for the benefit of {process.env.REACT_APP_COMPANY_NAME}
                      LTD, its subsidiaries, affiliates and its third party
                      content providers and licensors and each shall have the
                      right to assert and enforce such provisions directly or on
                      its own behalf.
                    </p>
                    <h3 className="title">
                      Disclaimer of Warranty: Limitation of Liability
                    </h3>
                    <p>
                      User expressly agrees that use of {process.env.REACT_APP_COMPANY_NAME} Site is at
                      User’s sole risk. Neither {process.env.REACT_APP_COMPANY_NAME}, its affiliates nor
                      any of their respective employees, agents, third party
                      content providers or licensors warrant that {process.env.REACT_APP_COMPANY_NAME}
                      Site will be uninterrupted or error free; nor do they make
                      any warranty as to the results that may be obtained from
                      use of {process.env.REACT_APP_COMPANY_NAME} Site, or as to the accuracy,
                      reliability or content of any information, service, or
                      merchandise provided through {process.env.REACT_APP_COMPANY_NAME} Site.
                    </p>
                    <p>
                      {process.env.REACT_APP_COMPANY_NAME} Site is provided on an “As Is” basis without
                      warranties of any kind, either express or implied,
                      including, but not limited to, warranties of title or
                      implied warranties of merchantability or fitness for a
                      particular purpose, other than those warranties which are
                      implied by and incapable of exclusion, restriction or
                      modification under the laws applicable to this agreement.
                    </p>
                    <p>
                      This disclaimer of liability applies to any damages or
                      injury, caused by any failure of performance, error,
                      omission, interruption, deletion, defect, delay in
                      operation or transmission, computer virus, communication
                      line failure, theft or destruction or unauthorized access
                      to, alteration of, or use of record, whether for breach of
                      contract, tortious behavior, negligence, or under any
                      other cause of action. User specifically acknowledges that
                      {process.env.REACT_APP_COMPANY_NAME} is not liable for the defamatory, offensive
                      or illegal conduct of other subscribers or third-parties
                      in cases including but not limited to any interactive
                      communication on or through the site and that the risk of
                      injury from the foregoing rests entirely with
                      subscriber(s).
                    </p>
                    <p>
                      In no event will {process.env.REACT_APP_COMPANY_NAME}, or any person or entity
                      involved in creating, producing or distributing {process.env.REACT_APP_COMPANY_NAME}
                      LTD Site or the {process.env.REACT_APP_COMPANY_NAME} Site software, be liable for
                      any damages, including, without limitation, direct,
                      indirect, incidental, special, consequential or punitive
                      damages arising out of the use of or inability to use
                      {process.env.REACT_APP_COMPANY_NAME} Site. User hereby acknowledges that the
                      provisions of this section shall apply to all content on
                      {process.env.REACT_APP_COMPANY_NAME} Site.
                    </p>
                    <p>
                      In addition to the terms set forth above neither, {process.env.REACT_APP_COMPANY_NAME}
                      LTD, not its affiliates, information providers or content
                      partners shall be liable regardless of the cause or
                      duration, for any errors, inaccuracies, omissions, or
                      other defects in, or untimeliness or unauthenticity of,
                      the information contained within {process.env.REACT_APP_COMPANY_NAME} Site, or for
                      any delay or interruption in the transmission thereof to
                      the user, or for any claims or losses arising therefrom or
                      occasioned thereby. None of the foregoing parties shall be
                      liable for any third-party claims or losses of any nature,
                      including, but not limited to, lost profits, punitive or
                      consequential damages. Prior to the execution of a stock
                      trade, subscribers are advised to consult with your broker
                      or other financial representative to verify pricing or
                      other information. {process.env.REACT_APP_COMPANY_NAME}, its affiliates,
                      information providers or content partners shall have no
                      liability for investment decisions based on the
                      information provided. Neither, {process.env.REACT_APP_COMPANY_NAME}, nor its
                      affiliates, information providers or content partners
                      warrant or guarantee the timeliness, sequence, accuracy or
                      completeness of this information.
                    </p>
                    <p>
                      ADDITIONALLY, THERE ARE NO WARRANTIES AS TO THE RESULTS
                      OBTAINED FROM THE USE OF THE INFORMATION
                    </p>
                    <h3 className="title">Breach</h3>
                    <p>
                      Without limiting other remedies, {process.env.REACT_APP_COMPANY_NAME} may limit or
                      terminate your activity, immediately remove your
                      information, warn other Users of your actions, immediately
                      temporarily/indefinitely suspend or terminate or block
                      your membership, and/or refuse to provide you with access
                      to the{process.env.REACT_APP_COMPANY_NAME} website in the event you are found to
                      be in a breach of any of the terms and conditions in this
                      Agreement.
                    </p>

                    <h3 className="title">Indemnity</h3>
                    <p>
                      You shall indemnify and hold harmless {process.env.REACT_APP_COMPANY_NAME}, its
                      owner, licensee, affiliates and their respective officers,
                      directors, agents, and employees, from any claim or
                      demand, or actions including reasonable attorneys’ fees,
                      made by any third party or penalty imposed due to or
                      arising out of your breach of this Terms of Use, privacy
                      Policy and other Policies, or your violation of any law,
                      rules or regulations or the rights of a third party.
                    </p>

                    <h3 className="title">Limitation of Liability</h3>
                    <p>
                      IN NO EVENT SHALL {process.env.REACT_APP_COMPANY_NAME} BE LIABLE FOR ANY SPECIAL,
                      INCIDENTAL, INDIRECT OR CONSEQUENTIAL DAMAGES OF ANY KIND
                      IN CONNECTION WITH THIS AGREEMENT, EVEN IF {process.env.REACT_APP_COMPANY_NAME}
                      HAS BEEN INFORMED IN ADVANCE OF THE POSSIBILITY OF SUCH
                      DAMAGES.
                    </p>
                    <h3 className="title">Monitoring</h3>
                    <p>
                      {process.env.REACT_APP_COMPANY_NAME} shall have the right, but not the obligation,
                      to monitor the content of {process.env.REACT_APP_COMPANY_NAME} Site, all
                      interactive communication including but not limited to
                      chat rooms and forums, to determine compliance with this
                      Agreement and any operating rules established by {process.env.REACT_APP_COMPANY_NAME}
                      LTD and to satisfy any law, regulation or authorized
                      government request. {process.env.REACT_APP_COMPANY_NAME} shall have the right in
                      its sole discretion to edit, refuse to post or remove any
                      material submitted to or posted on {process.env.REACT_APP_COMPANY_NAME} Site.
                      Without limiting the foregoing, {process.env.REACT_APP_COMPANY_NAME} shall have
                      the right to remove any material that {process.env.REACT_APP_COMPANY_NAME}, in its
                      sole discretion, finds to be in violation of the
                      provisions hereof or otherwise objectionable.
                    </p>

                    <h3 className="title">Indemnification</h3>
                    <p>
                      User agrees to defend, indemnify and hold harmless
                      {process.env.REACT_APP_COMPANY_NAME}, its affiliates and their respective
                      directors, officers, employees and agents from and against
                      all claims and expenses, including attorney’s fees,
                      arising out of the use of {process.env.REACT_APP_COMPANY_NAME} Site by User or
                      User’s Account.
                    </p>

                    <h3 className="title">Termination</h3>
                    <p>
                      Either {process.env.REACT_APP_COMPANY_NAME} or User may terminate this Agreement
                      at any time. Without limiting the foregoing, {process.env.REACT_APP_COMPANY_NAME}
                      shall have the right to immediately terminate User’s
                      Account in the event of any conduct by User which {process.env.REACT_APP_COMPANY_NAME}
                      LTD, in its sole discretion, considers to be unacceptable,
                      or in the event of any breach by User of this Agreement.
                    </p>
                    <h3 className="title">Trademarks</h3>
                    <p>
                      {process.env.REACT_APP_COMPANY_NAME} or any other trademarks and trade names being
                      shown or used on the site are the property of their
                      respective owners.
                    </p>

                    <h3 className="title">Third Party Content</h3>
                    <p>
                      Visitors may post reviews, comments, and other content;
                      and submit suggestions, ideas, comments, questions, or
                      other information, so long as the content is not illegal,
                      abusive, obscene, threatening, defamatory, invasive of
                      privacy, infringing on intellectual property rights, or
                      otherwise injurious to third parties or objectionable and
                      does not consist of or contain software viruses, political
                      campaigning, commercial solicitation, chain letters, mass
                      mailings, or any form of “spam.” You may not use a false
                      e-mail address, impersonate any person or entity, or
                      otherwise mislead as to the origin of a card or other
                      content. {process.env.REACT_APP_COMPANY_NAME} reserves the right (but not the
                      obligation) to remove or edit such content, but does not
                      regularly review posted content.
                    </p>
                    <p>
                      If you do post content or submit material, and unless we
                      indicate otherwise, you grant {process.env.REACT_APP_COMPANY_NAME} and its
                      affiliates a nonexclusive, royalty-free, perpetual,
                      irrevocable, and fully sub-licensable right to use,
                      reproduce, modify, adapt, publish, translate, create
                      derivative works from, distribute, and display such
                      content throughout the world in any media. You grant
                      {process.env.REACT_APP_COMPANY_NAME} and its affiliates and sub-licensees, the
                      right to use the name that you submit in connection with
                      such content, if they choose. You represent and warrant
                      that you own or otherwise control all of the rights to the
                      content that you post; that the content is accurate; that
                      use of the content you supply does not violate this policy
                      and will not cause injury to any person or entity; and
                      that you will indemnify and keep indemnified {process.env.REACT_APP_COMPANY_NAME}
                      or its affiliates for all claims resulting from content
                      you supply. {process.env.REACT_APP_COMPANY_NAME} has the right but not the
                      obligation to monitor and edit or remove any activity or
                      content. {process.env.REACT_APP_COMPANY_NAME} takes no responsibility and assumes
                      no liability for any content posted by you or any third
                      party.
                    </p>
                    <h3 className="title">Miscellaneous</h3>
                    <p>
                      This Agreement and any operating rules for {process.env.REACT_APP_COMPANY_NAME}
                      Site established by {process.env.REACT_APP_COMPANY_NAME} constitute the entire
                      agreement of the parties with respect to the subject
                      matter hereof, and supersede all previous written or oral
                      agreements between the parties with respect to such
                      subject matter. This Agreement shall be construed in
                      accordance with the laws of UK and courts in Mumbai shall
                      have the exclusive jurisdiction to entertain and try any
                      dispute arising out of or in relation to this Contract. No
                      waiver by either party of any breach or default hereunder
                      shall be deemed to be a waiver of any preceding or
                      subsequent breach or default. The section headings used
                      herein are for convenience only and shall not be given any
                      legal import.
                    </p>

                    <h3 className="title">JURISDICTION:</h3>
                    <p>
                      This Agreement shall be governed by and interpreted and
                      construed in accordance with the laws of UK. Any dispute
                      or litigation shall be subject to the Jurisdiction of the
                      Courts of Mumbai alone and not at any other place in UK or
                      outside its boundaries.
                    </p>
                    <p>
                      If at any time you believe this {process.env.REACT_APP_COMPANY_NAME} Site has not
                      adhered to these principles, please notify us by e-mail to
                      {process.env.REACT_APP_SUPPORT_EMAIL} and we will use all commercially
                      reasonable efforts to promptly determine and correct the
                      problem.
                    </p>
                    <p>
                      If you have questions about this policy, please e-mail us
                      at {process.env.REACT_APP_SUPPORT_EMAIL}
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    );
  }
}
export default Index;
