/**
 *  Success Screen
 */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { CopyText } from "../../services/copy";
import { connect } from "react-redux";
import { usersActions } from "../../actions";


class SuccessScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transactionID: "",
      products: [],
      isLoading:true,
      error:null
    };
  }

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    const id = query.get("id");
    const stripe = query.get("stripe");
    
    const {provider=''} = this.props?.match?.params || {}
    console.log(provider,query)
    if (id) {
      this.setState({...this.state,isLoading:true })
      this.props.getOrder(id)
      .then(({transactionID, products}={})=>
      {this.setState({ ...this.state,transactionID, products,isLoading:false })}
      ).catch((err) => {
        this.setState({ error:err.message,isLoading:false  });
      });
      
        
    }else if(stripe){
      this.setState({...this.state,isLoading:true })
      this.props.getStripeCheckoutId(stripe)
      .then(({transactionID, products}={})=>
      {this.setState({ ...this.state,transactionID, products,isLoading:false })}
      ).catch((err) => {
        this.setState({ error:err.message,isLoading:false  });
      });
    }else if(provider ==='gmacpey'){
      this.setState({...this.state,isLoading:true })
      const transactionID = query.get("transactionID"); 
      console.log('transactionID',transactionID)
      this.props.getGmacpeyCheckoutId(transactionID)
      .then(({transactionID, products}={})=>
      {this.setState({ ...this.state,transactionID, products,isLoading:false })}
      ).catch((err) => {
        this.setState({ error:err.message,isLoading:false  });
      });
    }

    var evt = document.createEvent("Event");
    evt.initEvent("load", false, false);
    window.dispatchEvent(evt);
    window.scrollTo(0, 0);
  }

  render() {

    const { error,transactionID='', products=[] ,isLoading} = this.state;
    return (
      <div>
        <div className="section-ptb">
          {products.length > 0 ? (
            <Container>
              <Row className="justify-content-center">
                <Col lg={7}>
                  <div className="success-screen">
                    <div className="thank-you text-center">
                      <i className="fa fa-check-circle-o"></i>
                      <h1 className="text-white">Thank You</h1>
                      <span>Success! We received your payment</span>
                      <strong className="text-white">
                        Transaction ID:{transactionID}
                      </strong>
                    </div>
                    <div className="pt-4 px-4 pt-md-5 px-md-5 pb-3" />

                    <div className="ordered-detail">
                      <h5 className="mb-4">Your Ordered Details</h5>

                      <div className="table-responsive">
                        <table class="table mb-0">
                          <th></th>
                          <th className="text-center">
                            <h6 className="mb-0">Name</h6>
                          </th>
                          <th className="text-center">
                            <h6 className="mb-0">Coupon Code</h6>
                          </th>
                          <th className="text-center">
                            <h6 className="mb-0">Value</h6>
                          </th>
                          <tbody>
                            {products.map(
                              ({ id, picture, name, currency, amount }) => (
                                <tr className="ordered-item">
                                  <td className="ordered-image">
                                    <img
                                      alt="img 01"
                                      src={`/api/v1/products/img/${picture}`}
                                      className="img-fluid"
                                    />
                                  </td>
                                  <td className="ordered-name">
                                    <span>{name}</span>
                                  </td>
                                  <td className="ordered-name">
                                    <span>{id}</span>

                                    <h6 className="mt-0">
                                      <Link>
                                        <CopyText
                                          text={id}
                                          buttonText="Click here to copy"
                                        />
                                      </Link>
                                    </h6>
                                  </td>
                                  <td className="ordered-price">
                                    <span>
                                      {`${currency?.symbol} ${amount}`}
                                    </span>
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                      <div className="table-responsive">
                        <table class="table total-table table-borderless mt-4 mb-0">
                          <tbody>
                            <tr>
                              <td>Total Value</td>
                              <td className="text-right">
                                {products[0].currency?.symbol}
                                {parseFloat(
                                  parseFloat(
                                    products.reduce(
                                      (fr, { price }) => fr + price,
                                      0
                                    )
                                  )
                                ).toLocaleString(navigator.language, {
                                  minimumFractionDigits: 2,
                                })}
                              </td>
                            </tr>
                            {parseFloat(
                              parseFloat(
                                products.reduce(
                                  (fr, { discount }) => fr + discount,
                                  0
                                )
                              )
                            ).toLocaleString(navigator.language, {
                              minimumFractionDigits: 2,
                            }) > 0 && (
                              <tr>
                                <td>Your profit</td>
                                <td className="text-right">
                                  {products[0].currency?.symbol}
                                  {parseFloat(
                                    parseFloat(
                                      products.reduce(
                                        (fr, { discount }) => fr + discount,
                                        0
                                      )
                                    )
                                  ).toLocaleString(navigator.language, {
                                    minimumFractionDigits: 2,
                                  })}
                                </td>
                              </tr>
                            )}
                            <tr className="border-top">
                              <td>
                                <strong className="h5">Total payment</strong>
                              </td>
                              <td className="text-right h5">
                                <strong>
                                  {products[0].currency?.symbol}
                                  {parseFloat(
                                    parseFloat(
                                      products.reduce(
                                        (fr, { salePrice }) => fr + salePrice,
                                        0
                                      )
                                    )
                                  ).toLocaleString(navigator.language, {
                                    minimumFractionDigits: 2,
                                  })}
                                </strong>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="d-sm-flex px-4 pb-4 px-md-5 pb-md-5">
                      <Link className="button ml-auto" to="/">
                        Go to home
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          ) : (
            <Container>
              {isLoading ? 
                            <div className="lazyload-img"></div>:<>
              {error ?<p>{error}</p> :<div>Order Not Found</div>}</>}
            </Container>
          )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (state) => ({
  order: state.coupons.order,
});
const userDispatchToProps = (dispatch) => {
  return {
    getOrder: async (id) => {
      return await dispatch(usersActions.getOrder(id));
    },
    getStripeCheckoutId: async (id) => {
      return await dispatch(usersActions.getStripeCheckoutId(id));
    },
    getGmacpeyCheckoutId: async (id) => {
      return await dispatch(usersActions.getGmacpeyCheckoutId(id));
    }
  };
};
export default connect(mapDispatchToProps, userDispatchToProps)(SuccessScreen);

