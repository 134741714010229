import React from 'react';
import ReactDOM from 'react-dom';

class PayPalButton extends React.Component {
  static defaultProps = {
    style: {},
    options: {
      clientId: 'sb',
      currency: 'USD',
    },
    shippingPreference: 'GET_FROM_FILE',
  };

  constructor(props) {
    super(props);

    this.state = {
      isSdkReady: false,
    };
  }

  componentWillUnmount() {
    // resetting all pay-pal related properties
    Object.keys(window).forEach((key) => {
        if (/paypal|zoid|post_robot/.test(key)) {

            delete window[key];
        }
    });
}

  componentDidMount() {
    this.setState({ isSdkReady: false });
    if (typeof window !== 'undefined' && window !== undefined && window.paypal === undefined) {
      this.addPaypalSdk();
    } else if (
      typeof window !== 'undefined' &&
      window !== undefined &&
      window.paypal !== undefined &&
      this.props.onButtonReady
    ) {
      this.props.onButtonReady();
    }else if (typeof window !== 'undefined' &&
    window !== undefined &&
    window.paypal !== undefined){
      this.setState({ isSdkReady: true });
    }
  }

  createOrder(data, actions) {
    const { currency, options, amount, shippingPreference } = this.props;

    const currency_code = currency
              ? currency
              : options && options.currency
              ? options.currency: 'USD'
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            currency_code,
            value: amount.toString(),
            breakdown: {
              item_total: {value: amount.toString() ,currency_code}
          }
          },
          items:[
            {name:'video game',
            quantity:'1',
            unit_amount:{
              currency_code: currency_code,
              value: amount.toString(),
            }}
          ]
        },
      ],
      application_context: {
        shipping_preference: shippingPreference,
      },
    });
  }

  onApprove(data, actions) {
    return actions.order
      .capture()
      .then((details) => {
        if (this.props.onSuccess) {
          return this.props.onSuccess(details, data);
        }
      })
      .catch((err) => {
        if (this.props.catchError) {
          return this.props.catchError(err);
        }
      });
  }

  render() {
    const { amount, onSuccess, createOrder, createSubscription, onApprove, style } = this.props;
    const { isSdkReady } = this.state;

    if (!isSdkReady) {
      return <div className="lazyload-img-v2"></div>;
    }

    const Button = window.paypal.Buttons.driver('react', {
      React,
      ReactDOM,
    });

    const createOrderFn =
      amount && !createOrder
        ? (data, actions) => this.createOrder(data, actions)
        : (data, actions) => createOrder(data, actions);

    return (
      <Button
        {...this.props}
        createOrder={createSubscription ? undefined : createOrderFn}
        createSubscription={createSubscription}
        onApprove={
          onSuccess
            ? (data, actions) => this.onApprove(data, actions)
            : (data, actions) => onApprove(data, actions)
        }
        style={{ size: 'responsive', label: 'buynow' }}
      />
    );
  }

  addPaypalSdk() {
    const { options, onButtonReady } = this.props;
    const queryParams = [];

    // replacing camelCase with dashes
    Object.keys(options).forEach((k) => {
      const name = k
        .split(/(?=[A-Z])/)
        .join('-')
        .toLowerCase();
      queryParams.push(`${name}=${options[k]}`);
    });

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `https://www.paypal.com/sdk/js?${queryParams.join('&')}`;
    script.async = true;
    script.onload = () => {
      this.setState({ isSdkReady: true });

      if (onButtonReady) {
        onButtonReady();
      }
    };
    script.onerror = () => {
      throw new Error('Paypal SDK could not be loaded.');
    };

    document.body.appendChild(script);
  }
}

export { PayPalButton };
