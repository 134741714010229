/**
 *  Admin Header
 */
import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { usersActions } from '../../actions';
import {
  Col,
  Collapse,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarToggler,
  NavItem,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';
import { connect } from 'react-redux';
import profile from '../../assets/images/testimonials/img-02.jpg';

const logo = process.env.REACT_APP_LOGO
class AdminHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      modal1: false,
      dropdownOpen: false,
      isOpen: false,
    };
    this.toggle2 = this.toggle2.bind(this);
    this.toggle3 = this.toggle3.bind(this);
  }
  toggle2() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }
  toggle3() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }
  Changeclass(val) {
    var removeelems = document.getElementsByClassName('nav-item');
    [].forEach.call(removeelems, function (el) {
      el.classList.remove('active');
    });

    /*if (val == "report") {
      document.querySelector(".report").classList.add("active");
    }*/
    if (val == 'invoice') {
      document.querySelector('.invoice').classList.add('active');
    }
    /*if (val == "profile") {
      document.querySelector(".profile").classList.add("active");
    }*/
    if (val == 'backhome') {
      document.querySelector('.backhome').classList.add('active');
    }
  }

  render() {
    const { user } = this.props;

    return (
      <div>
        <div className="admin-menu">
          <Container>
            <Row className="align-items-center">
              <Col md={12}>
                <div className="d-flex align-items-center positive-reletive">
                  <Link to="/">
                    <img className="img-fluid logo" src={require(`../../assets/images/${logo}`)} alt="logo" />
                  </Link>
                  {/*
                  <Dropdown
                    isOpen={this.state.dropdownOpen}
                    toggle={this.toggle2}
                    className="profile-dropdown ml-auto"
                  >
                    <DropdownToggle caret className="btn-white">
                      <div className="d-none d-sm-block">
                        <h6 className="mb-0">
                          {user.info.firstName +
                            " " +
                            (user.info.lastName || "")}
                        </h6>
                        <span className="text-dark">{user.info.email}</span>
                      </div>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        onClick={() => this.Changeclass("profile")}
                        className="nav-link"
                        tag={Link}
                        to="/admin-panel/Profile"
                      >
                        <i className="fa fa-user-circle-o"></i>Profile
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => this.Changeclass("profile")}
                        className="nav-link"
                        tag={Link}
                        to="/admin-panel/Settings"
                      >
                        <i className="fa fa-cog"></i>Account settings
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          this.Changeclass("profile");
                          this.props.logout();
                        }}
                        className="nav-link"
                        tag={Link}
                        to="/"
                      >
                        <i className="fa fa-sign-out"></i>
                        Logout
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>*/}
                </div>
                <Navbar light expand="md" className="bg-white">
                  <NavbarToggler onClick={this.toggle3} />
                  <Collapse isOpen={this.state.isOpen} navbar>
                    <Nav navbar>
                      {/*<NavItem className="active report">
                        <Link
                          to="/admin-panel/Reports"
                          className="nav-link"
                          onClick={() => this.Changeclass('report')}
                        >
                          <i className="fa fa-line-chart"></i>Reports
                        </Link>
                </NavItem>*/}
                <NavItem className="orders">
                        <Link
                          to="/admin-panel/Orders"
                          className="nav-link"
                          onClick={() => this.Changeclass('orders')}
                        >
                          <i className="fa fa-inbox"></i>Orders
                        </Link>
                      </NavItem>
                      <NavItem className="coupons">
                        <Link
                          to="/admin-panel/Coupons"
                          className="nav-link"
                          onClick={() => this.Changeclass('coupons')}
                        >
                          <i className="fa fa-inbox"></i>Coupons
                        </Link>
                      </NavItem>
                      <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle nav caret>
                          <i className="fa fa-shopping-cart"></i>Products
                        </DropdownToggle>
                        <DropdownMenu left>
                          <DropdownItem
                            onClick={() => this.Changeclass('product')}
                            className="nav-link"
                            tag={Link}
                            to="/admin-panel/Product"
                          >
                            <i className="fa fa-cart-plus"></i>Products
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => this.Changeclass('product')}
                            className="nav-link"
                            tag={Link}
                            to="/admin-panel/product-add"
                          >
                            <i className="fa fa-cart-arrow-down"></i>Add Product
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                      <NavItem className="merchants">
                        <Link
                          to="/admin-panel/Collaboration"
                          className="nav-link"
                          onClick={() => this.Changeclass('merchants')}
                        >
                          <i className="fa fa-map-marker"></i>Merchants
                        </Link>
                      </NavItem>
                      {/*<NavItem className="profile">
                        <Link
                          to="/admin-panel/Profile"
                          className="nav-link"
                          onClick={() => this.Changeclass('profile')}
                        >
                          <i className="fa fa-user-circle-o"></i>Profile
                        </Link>
              </NavItem>*/}
                      <NavItem className="backhome">
                        <Link
                          to="/"
                          className="nav-link"
                          onClick={() => this.Changeclass('backhome')}
                        >
                          <i className="fa fa-home"></i>Back to home
                        </Link>
                      </NavItem>
                    </Nav>
                  </Collapse>
                </Navbar>
              </Col>
            </Row>
          </Container>
        </div>
        {this.props.children}
      </div>
    );
  }
}

const userStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const userDispatchToProps = (dispatch) => {
  return {
    logout: () => {
      dispatch({ type: usersActions.LOGOUT });
    },
  };
};

export default connect(userStateToProps, userDispatchToProps)(AdminHeader);
