import React, { Fragment } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';

import Headers from './layouts/header/Header';
import Footer from './layouts/footer/Footer';

//Component
import HomePage from './component/home/index';

import './App.css';
import './Vendor.js';

import { IntlProvider } from 'react-intl';
import ShopPage from './component/shop';
import ShopPage1 from './component/shop/index1';
import ShopPage2 from './component/shop/index2';
import ShopPage3 from './component/shop/index3';
import ShopPage4 from './component/shop/index4';
import ProductDetail from './component/shop/product-detail';
import ProductCoupon from './component/shop/product-coupon';
import ShopingCart from './component/shop/ShopingCart';
import ShopingCartcop from './component/shop/ShopingCartcop';
import MyAccount from './component/Account/MyAccount';
import PageNotFound from './component/Pages/PageNotFound';
import ComingSoon from './component/Pages/ComingSoon';
import Logout from './component/Forms/Logout';

import BlogSinglePage from './component/Blog/BlogSinglePage';
import WishList from './component/WishList/WishList';
import Aboutus from './component/AboutUs/Aboutus';
import Contactus from './component/ContactUs/Contactus';
import Terms from './component/Terms';
import RefundPolicy from './component/RefundPolicy';
import Maintenance from './component/Pages/Maintenance';
import BlogFullWidth from './component/Blog/BlogFullWidth';
import CheckOut from './component/shop/CheckOut';
import CheckOutCop from './component/shop/CheckOutCop';

import Address from './component/Account/Address';
import Addressedit from './component/Account/Addressedit';
import AccountProfile from './component/Account/AccountProfile';
import AccountProfileedit from './component/Account/AccountProfileedit';
import SavedCards from './component/Account/SavedCards';
import SavedCardsedit from './component/Account/SavedCardsedit';
import SuccessScreen from './component/Account/SuccessScreen';
import CouponInvoice from './component/Account/CouponInvoice';

import Reports from './component/admin/Reports';
import Coupons from './component/admin/Coupons';

import OrderHistory from './component/Account/OrderHistory';
import AdminDashboard from './component/admin';
import MarchantDashboard from './component/marchant';
import SavedCardsadd from './component/Account/SavedCardsadd';
import PaypalCheckout from './widgets/paypal/cheakout'
import PaypalSuccess from './widgets/paypal/success'
import StripeSuccess from './widgets/stripe/success'
import { receiveProducts } from './actions';
import { connect } from 'react-redux';

class App extends React.Component {
  constructor(props) {
    super(props);
  }
  componentWillMount() {
    this.props.receiveProducts();
  }
  getUrl(pathname) {
    let pathArray = pathname.split('/');
    return `/${pathArray[1]}` === '/ComingSoon'
      ? true
      : `/${pathArray[1]}` === '/Maintenance'
      ? true
      : `/${pathArray[1]}` === '/admin-panel'
      ? true
      : `/${pathArray[1]}` === '/marchant-panel'
      ? true
      : `/${pathArray[1]}` === '/paypal'
      ? true
      : `/${pathArray[1]}` === '/stripe'
      ? true
      : false;
  }

  render() {
    const { location } = this.props;
    return (
      <IntlProvider locale="a" messages="s">
        <Fragment>
          {this.getUrl(location.pathname) ? (
            <Switch>
              <Route path="/ComingSoon" component={ComingSoon} />
              <Route path="/Maintenance" component={Maintenance} />
              <Route path="/admin-panel" component={AdminDashboard} />
              <Route path="/marchant-panel" component={MarchantDashboard} />
              <Route path="/paypal/success" component={PaypalSuccess} />
              <Route path="/paypal" component={PaypalCheckout} />
              <Route path="/stripe/success" component={StripeSuccess} />
              
            </Switch>
          ) : (
            <div>
              <Headers />
              <Switch>
                <Route exact path="/" component={HomePage} />
                <Route exact path="/sidebar-with-load-more" component={ShopPage} />
                <Route exact path="/shop" component={ShopPage1} />
                <Route exact path="/shopshop" component={ShopPage4} />
                <Route exact path="/topbar-without-lazyload" component={ShopPage3} />
                <Route exact path="/sidebar-with-lazyload" component={ShopPage4} />
                <Route exact path="/ShopingCart" component={ShopingCart} />
                <Route exact path="/ShopingCartcop" component={ShopingCartcop} />
                <Route exact path="/MyAccount" component={MyAccount} />
                <Route exact path="/BlogSinglePage" component={BlogSinglePage} />
                <Route exact path="/BlogFullWidth" component={BlogFullWidth} />
                <Route exact path="/wishlist" component={WishList} />
                <Route exact path="/Aboutus" component={Aboutus} />
                <Route exact path="/Terms" component={Terms} />
                <Route exact path="/RefundPolicy" component={RefundPolicy} />
                <Route exact path="/contactus" component={Contactus} />
                <Route exact path="/CheckOut" component={CheckOut} />
                <Route exact path="/CheckOutCop" component={CheckOutCop} />
                <Route exact path="/Address" component={Address} />
                <Route exact path="/Logout" component={Logout} />

                <Route exact path="/Account/Addressedit" component={Addressedit} />
                <Route exact path="/Account/AccountProfile" component={AccountProfile} />
                <Route exact path="/Account/Address" component={Address} />
                <Route exact path="/Account/OrderHistory" component={OrderHistory} />
                <Route exact path="/Account/SavedCards" component={SavedCards} />
                <Route exact path="/Account/AccountProfileedit" component={AccountProfileedit} />
                <Route exact path="/Account/SavedCards" component={SavedCards} />
                <Route exact path="/Account/SavedCardsedit" component={SavedCardsedit} />
                <Route exact path="/Account/SavedCardsadd" component={SavedCardsadd} />
                <Route exact path="/SuccessScreen" component={SuccessScreen} />
                <Route exact path="/CouponInvoice" component={CouponInvoice} />
                <Route exact path="/CouponInvoice/:provider" component={CouponInvoice} />
                <Route exact path="/Reports" component={Reports} />
                <Route exact path="/Coupons" component={Coupons} />
                <Route path={`/shop/:category/:id`} component={ProductDetail} />
                <Route path={`/shopshop/coupon/:id`} exact component={ProductCoupon} />
                <Route exact component={PageNotFound} />
              </Switch>
              <Footer />
            </div>
          )}
        </Fragment>
      </IntlProvider>
    );
  }
}

const AppMapStateToProps = (state) => {
  return {
    products: state.data.products,
  };
};

const AppMapDispatchToProps = (dispatch) => {
  return {
    receiveProducts: () => {
      dispatch(receiveProducts());
    },
  };
};

export default connect(AppMapStateToProps, AppMapDispatchToProps)(withRouter(App));
