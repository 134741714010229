import ProductsList from "../api/product_v2.json";
import * as usersActions from "./users";
import * as adminActions from "./admin";

export const receiveProducts = () => {
  return async (dispatch) => {
    const productdata = ProductsList;
    dispatch({
      type: "ACTUAL_PRODUCTS",
      products: productdata,
    });
  };
};

export { usersActions, adminActions };
