/**
 *  Shop Main Page
 */
import { Button } from "antd";
import debounce from "lodash.debounce";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import ProductList from "../../widgets/ProductList2";
import TopFilter from "../../widgets/shopfilter/TopFilter";
import { adminActions } from "../../actions";

class ShopPage4 extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      limit: 20,
      hasMoreProduct: true,
      getproduct: [],
      lastScrollTop: 0,
      isloading: false,
    };
  }
  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    const comp = query.get("comp");
    if (comp) {
      this.props.getCompanyProducts(comp).catch((err) => console.log(err));
    }
  }
  render() {
    let { products } = this.props;
    const { sidebarmenu } = this.state;
    let layoutstyle = localStorage.getItem("setLayoutStyle");

    if (layoutstyle == null) {
      layoutstyle = localStorage.setItem("setLayoutStyle", "col-sm-6 col-md-4");
    }

    return (
      <div className="site-content">
        {sidebarmenu ? <div className="overlay"></div> : null}
        <div className="inner-intro">
          <Container>
            <Row className="intro-title align-items-center">
              <Col md={6} className="text-left">
                <div className="intro-title-inner">
                  <h1>Shop</h1>
                </div>
              </Col>
              <Col md={6} className="text-right">
                <ul className="ciyashop_breadcrumbs page-breadcrumb breadcrumbs">
                  <li className="home">
                    <span>
                      <Link className="bread-link bread-home" to="/">
                        Home
                      </Link>
                    </span>
                  </li>
                  <li>
                    <span>Products</span>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </div>
        <Container>
          <div className="products-header pt-5">
            <div className="loop-header">
              <div className="loop-header-tools">
                <div className="loop-header-tools-wrapper">
                  <TopFilter productlength={products.length} />
                  {/*<Button
                    className="filter-overlay-btn"
                    onClick={this.showfilter}
                  >
                    <i className="fa fa-filter"> </i> Filter
                  </Button>*/}
                </div>
              </div>
            </div>
          </div>
        </Container>
        <div className="content-wrapper section-pt mb-3 mb-md-5">
          {/*<div
            className={
              "sidebar sidebar-overlay desktop " +
              (sidebarmenu ? "sidebar-overlay-open" : "")
            }
            ref={this.setSidebar}
          >
            <a class="sidebar-overlay-close" onClick={this.showfilter}></a>
            <div className="shop-sidebar-widgets">
              <SideFilter />
              <SocialFilter />
              <ShopBanner />
            </div>
          </div>*/}
          <Container>
            <Row>
              <div className="content col-xl-12 col-lg-12">
                {products.length > 0 ? (
                  <div>
                    <Row className="products products-loop grid ciyashop-products-shortcode pgs-product-list">
                      {products
                        .slice(0, this.state.limit)
                        .map((product, index) => (
                          <ProductList
                            product={product}
                            key={index}
                            layoutstyle={layoutstyle}
                          />
                        ))}
                    </Row>
                    {this.state.isloading && (
                      <div className="lazyload-img"></div>
                    )}
                  </div>
                ) : (
                  <Row className="products products-loop grid ciyashop-products-shortcode">
                    <div className="col-sm-12 text-center  mt-5">
                      <img
                        src={require(`../../assets/images/empty-search.jpg`)}
                        className="img-fluid mb-4"
                      />
                      <h3>
                        Sorry! No products were found matching your selection!{" "}
                      </h3>
                      <p>Please try to other words.</p>
                      <Link to="/shop" className="btn btn-solid">
                        Continue Shopping
                      </Link>
                    </div>
                  </Row>
                )}
              </div>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (state) => ({
  products: state.coupons.products,
});
const userDispatchToProps = (dispatch) => {
  return {
    getCompanyProducts: async (id) => {
      await dispatch(adminActions.getCompanyProducts(id));
    },
  };
};
export default connect(mapDispatchToProps, userDispatchToProps)(ShopPage4);
