import { usersActions } from "../actions";

export default function user(
  state = {
    info: JSON.parse(localStorage.getItem("user")) || null,
    expiresAt: localStorage.getItem("expiresAt") || null,
    isAdmin: JSON.parse(localStorage.getItem("isAdmin")) || null,
    companyAdmin: JSON.parse(localStorage.getItem("companyAdmin")) || null,
    isLoading: true,
  },
  action
) {
  switch (action.type) {
    case usersActions.SET_USER:
      return {
        ...state,
        [`${action.key}`]: action.value,
      };
    case usersActions.SET_INFO:
      const info = { ...state.info, ...action.info };
      localStorage.setItem("user", JSON.stringify(info));
      return {
        ...state,
        info: info,
      };
    case usersActions.LOGIN:
      localStorage.setItem("user", JSON.stringify(action.user));
      localStorage.setItem("expiresAt", action.expiresAt);
      localStorage.setItem("isAdmin", action.isAdmin || null);
      localStorage.setItem("companyAdmin", action.companyAdmin || null);
      return {
        info: action.user,
        expiresAt: action.expiresAt,
        isAdmin: action.isAdmin || null,
        companyAdmin: action.companyAdmin
      };
    case usersActions.LOGOUT:
      localStorage.clear();
      return {
        info: null,
        expiresAt: null,
      };
    case usersActions.SET_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    default:
      return state;
  }
}
