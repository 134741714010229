/**
 *  Admin Invoive Page
 */
 import React, { Component } from "react";
 import { Link } from "react-router-dom";
 import ReactTable from "react-table";
 import {
   Col,
   Container,
   Modal,
   ModalBody,
   ModalFooter,
   ModalHeader,
   Row,
 } from "reactstrap";
 import invoice from "../../api/invoice.js";
 import { adminActions } from "../../actions";
 import { connect } from "react-redux";
 import { ISO8601format } from "../../functions/date";
 import { CopyText } from "../../services/copy";
 class Invoices extends Component {
   constructor(props) {
     super(props);
     this.state = {
       modal: false,
       modal1: false,
       dropdownOpen: false,
       isOpen: false,
       invoices: invoice,
       searchProduct: "",
       invoiceview: "",
     };
 
     this.toggle = this.toggle.bind(this);
     this.toggle1 = this.toggle1.bind(this);
   }
   toggle() {
     this.setState((prevState) => ({
       modal: !prevState.modal,
     }));
   }
   toggle1() {
     this.setState((prevState) => ({
       modal1: !prevState.modal1,
     }));
   }
 
   componentDidMount() {
     window.scrollTo(0, 0);
     this.props.getOrders().then(() => {
       this.setState({ invoices: this.props.orders });
     });
   }
 
   onSearchProduct(searchText) {
     if (searchText === "") {
       this.setState({
         ...this.state,
         invoices: this.props.orders,
         searchProduct: searchText,
       });
     } else {
       let SearchBuyer = this.props.orders.filter((invo) => {
         if (searchText === searchText.toLowerCase()) {
           let id =
             invo.id.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
             let tran = invo.transactionID.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
           return id || tran;
         } else {
           let id =
             invo.id.toUpperCase().indexOf(searchText.toUpperCase()) > -1;
             let tran =
             invo.transactionID.toUpperCase().indexOf(searchText.toUpperCase()) > -1;
           return id || tran;
         }
       });
       this.setState({
         ...this.state,
         searchProduct: searchText,
         invoices: SearchBuyer,
       });
     }
   }
   onDeleteInvoicePopup(data) {
     this.data = data;
     this.toggle1();
   }
   onDeleteInvoice(res) {
     if (res) {
       let deleteInvoice = this.data;
       let newdata = this.state.invoices.filter(
         (InvoiceList) => InvoiceList.id !== deleteInvoice.id
       );
       this.setState({
         ...this.state,
         modal1: false,
         invoices: newdata,
       });
     }
   }
 
   onViewInvoicePopup(data) {
     this.setState({
       invoiceview: data,
     });
     this.toggle();
   }
 
   render() {
     const {transactionID='',products=[]} = this.state.invoiceview;
     const columns = [
       /*{
         maxWidth: 75,
         Header: "No.",
         accessor: "idd",
       },*/
       {
         sortable: false,
         Header: "Order ID",
         accessor: "id",
       },
       {
         Header: "Transaction ID",
         accessor: "transactionID",
       },
       {
         Header: "Date",
         accessor: "date",
         Cell: (props) => {
           return (
             <div>
               {props.value ? new Date(props.value).toLocaleString("en-GB") : ""}
             </div>
           );
         },
       },
       {
         Header: "Status",
         accessor: "status",
       },
       {
        Header: "Amount",
        accessor: "products",
        minWidth: 50,
        Cell: (props) => {
          const {products=[]} = props.original
          return (
            <div>
                                        {products[0]?.currency?.symbol}
                                        {parseFloat(
                                          parseFloat(
                                            products.reduce(
                                              (fr, { salePrice }) =>
                                                fr + salePrice,
                                              0
                                            )
                                          )
                                        ).toLocaleString(navigator.language, {
                                          minimumFractionDigits: 2,
                                        })}
            </div>
          );
        }},
        {
          Header: "Coupons",
          accessor: "products",
          minWidth: 50,
          Cell: (props) => {
            return (
              <div>
                <Link
                  className="view-button"
                  onClick={() => this.onViewInvoicePopup(props.original)}
                >
                  {" "}
                  View <i className="fa fa-eye pl-2"></i>
                </Link>
                {/*<a
                  className="delete-button"
                  onClick={() => this.onDeleteInvoicePopup(props.original)}
                >
                  Delete <i className="fa fa-trash-o pl-2"></i>
                </a>*/}
              </div>
            );
          }}
     ];
 
     return (
       <div>
         <div className="section-ptb">
           <Container>
             <Row>
               <Col lg={12}>
                 <div className="mb-0">
                   <h4>Orders List</h4>
                 </div>
                 <div className="mb-4">
                   <form>
                     <div class="form-group">
                       <input
                         type="text"
                         class="form-control"
                         placeholder="Search Order"
                         value={this.state.searchProduct}
                         onChange={(e) => this.onSearchProduct(e.target.value)}
                       ></input>
                     </div>
                   </form>
                 </div>
                 <ReactTable
                   className="invoices-table"
                   data={this.state.invoices}
                   columns={columns}
                   minRows={1}
                   defaultPageSize={5}
                 />
                 {/* modal-view */}
                  <Modal
                    isOpen={this.state.modal}
                    toggle={this.toggle}
                    className="modal-view modal-lg modal-dialog-centered"
                  >
                    <ModalHeader toggle={this.toggle}></ModalHeader>
                    {transactionID !== null ? (
                      <ModalBody>
                        <div className="success-screen">
                          <div className="thank-you text-center">
                            <h1 className="text-white">Order Details</h1>
                            <strong className="text-white">
                              Transaction ID:{transactionID}
                            </strong>
                          </div>
                          <div className="pt-4 px-4 pt-md-5 px-md-5 pb-3" />

                          <div className="ordered-detail">
                            <h5 className="mb-4">Coupons:</h5>

                            <div className="table-responsive">
                              <table class="table mb-0">
                                <th></th>
                                <th className="text-center">
                                  <h6 className="mb-0">Name</h6>
                                </th>
                                <th className="text-center">
                                  <h6 className="mb-0">Status</h6>
                                </th>
                                <th className="text-center">
                                  <h6 className="mb-0">Coupon Code</h6>
                                </th>
                                <th className="text-center">
                                  <h6 className="mb-0">Value</h6>
                                </th>
                                <tbody>
                                  {products.map(
                                    ({
                                      id,
                                      picture,
                                      name,
                                      currency,
                                      amount,
                                      isValid,
                                    }) => (
                                      <tr className="ordered-item">
                                        <td className="ordered-image">
                                          <img
                                            alt="img 01"
                                            src={`/api/v1/products/img/${picture}`}
                                            className="img-fluid"
                                          />
                                        </td>
                                        <td className="ordered-name">
                                          <span>{name}</span>
                                        </td>
                                        <td className="ordered-name">
                                          <span>
                                            {isValid ? "Valid" : "Activated"}
                                          </span>
                                        </td>
                                        <td className="ordered-name">
                                          <span>{id}</span>

                                          <h6 className="mt-0">
                                            <Link>
                                              <CopyText
                                                text={id}
                                                buttonText="Click here to copy"
                                              />
                                            </Link>
                                          </h6>
                                        </td>
                                        <td className="ordered-price">
                                          <span>
                                            {`${currency?.symbol} ${amount}`}
                                          </span>
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>
                            </div>
                            <div className="table-responsive">
                              <table class="table total-table table-borderless mt-4 mb-0">
                                <tbody>
                                  <tr>
                                    <td>Total Value</td>
                                    <td className="text-right">
                                      {products[0]?.currency?.symbol}
                                      {parseFloat(
                                        parseFloat(
                                          products.reduce(
                                            (fr, { price }) => fr + price,
                                            0
                                          )
                                        )
                                      ).toLocaleString(navigator.language, {
                                        minimumFractionDigits: 2,
                                      })}
                                    </td>
                                  </tr>
                                  {parseFloat(
                                    parseFloat(
                                      products.reduce(
                                        (fr, { discount }) => fr + discount,
                                        0
                                      )
                                    )
                                  ).toLocaleString(navigator.language, {
                                    minimumFractionDigits: 2,
                                  }) > 0 && (
                                    <tr>
                                      <td>Your profit</td>
                                      <td className="text-right">
                                        {products[0]?.currency?.symbol}
                                        {parseFloat(
                                          parseFloat(
                                            products.reduce(
                                              (fr, { discount }) =>
                                                fr + discount,
                                              0
                                            )
                                          )
                                        ).toLocaleString(navigator.language, {
                                          minimumFractionDigits: 2,
                                        })}
                                      </td>
                                    </tr>
                                  )}
                                  <tr className="border-top">
                                    <td>
                                      <strong className="h5">
                                        Total payment
                                      </strong>
                                    </td>
                                    <td className="text-right h5">
                                      <strong>
                                        {products[0]?.currency?.symbol}
                                        {parseFloat(
                                          parseFloat(
                                            products.reduce(
                                              (fr, { salePrice }) =>
                                                fr + salePrice,
                                              0
                                            )
                                          )
                                        ).toLocaleString(navigator.language, {
                                          minimumFractionDigits: 2,
                                        })}
                                      </strong>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </ModalBody>
                    ) : null}
                  </Modal>
 
                 {/* modal-delete 
                 <Modal
                   isOpen={this.state.modal1}
                   toggle={this.toggle1}
                   className="modal-delete modal-lg modal-dialog-centered"
                 >
                   <ModalHeader toggle={this.toggle1}></ModalHeader>
                   <ModalBody>
                     Are You Sure You Want To Delete This Invoice Permanently?
                   </ModalBody>
                   <ModalFooter className="justify-content-center pt-4">
                     <Link
                       className="action-button"
                       to="#"
                       onClick={(res) => this.onDeleteInvoice(res)}
                     >
                       Yes
                     </Link>
                     <Link
                       className="action-button no"
                       to="#"
                       onClick={this.toggle1}
                     >
                       No
                     </Link>
                   </ModalFooter>
                 </Modal>*/}
               </Col>
             </Row>
           </Container>
         </div>
       </div>
     );
   }
 }
 const userStateToProps = (state) => {
   return {
     orders: state.orders.orders,
   };
 };
 
 const userDispatchToProps = (dispatch) => {
   return {
     getOrders: async () => {
       await dispatch(adminActions.getOrders());
     },
   };
 };
 
 export default connect(userStateToProps, userDispatchToProps)(Invoices);
 