import React from "react";
import { useForm } from "react-hook-form";
import { usersActions } from "../../actions";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";

const RegisterForm = ({ children, onFinish }) => {
  const {
    register,
    handleSubmit,
    setError,
    errors,
    getValues,
    clearErrors,
  } = useForm();
  const dispatch = useDispatch();
  const onSubmit = (data, e) => {
    const { name, email, password, password2 } = getValues();
    let err = false;
    if (name.length < 1) {
      setError("name", {
        type: "manual",
        message: "Please enter name",
      });
      err = true;
    }
    if (password.length < 6) {
      setError("password", {
        type: "manual",
        message: "password is too short, must be at least 6 characters",
      });
      err = true;
    }
    if (password !== password2) {
      setError("password2", {
        type: "manual",
        message: "Invalid Password confirmation",
      });
      err = true;
    }

    if (email !== "undefined") {
      if (
        !email.match(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
      ) {
        setError("email", {
          type: "manual",
          message: "Please Enter Valid Email Address",
        });
        err = true;
      }
    }

    if (err) return;

    dispatch(usersActions.createUser(data))
      .then(() => {
        toast.success("Registrarion Succeeded", { autoClose: 4000 });
        onFinish();
      })
      .catch((err) => {
        const message =
          err?.statusCode === 244 ? err.message : "Internal error";
        toast.warning(message, { autoClose: 5000 });
      });
  };
  const onError = (errors, e) => console.log(errors, e);

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      <div class="form-group">
        <label>Name</label>
        <input
          type="text"
          class="form-control"
          placeholder="Name"
          name="name"
          ref={register}
          onBlur={() => {
            const name = getValues("name");
            if (name.length < 1) {
              setError("name", {
                type: "manual",
                message: "Please enter name",
              });
            }
          }}
          onFocus={() => {
            clearErrors(["name"]);
          }}
          required
        ></input>
        {errors.name && <p class="error-input-text">{errors.name.message}</p>}
      </div>
      <div class="form-group">
        <label>Email address</label>
        <input
          type="email"
          class="form-control"
          placeholder="Enter email"
          name="email"
          ref={register}
          onBlur={() => {
            const email = getValues("email");
            if (
              !email.match(
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              )
            ) {
              setError("email", {
                type: "manual",
                message: "Please Enter Valid Email Address",
              });
            }
          }}
          onFocus={() => {
            clearErrors(["email"]);
          }}
          required
        ></input>
        {errors.email && <p class="error-input-text">{errors.email.message}</p>}
      </div>
      <div class="form-group">
        <label>Password </label>
        <input
          type="password"
          class="form-control"
          placeholder="Password"
          name="password"
          ref={register}
          onBlur={() => {
            const pass = getValues("password");
            if (pass.length < 6) {
              setError("password", {
                type: "manual",
                message: "password is too short, must be at least 6 characters",
              });
            }
          }}
          onFocus={() => {
            clearErrors(["password"]);
          }}
          required
        ></input>
        {errors.password && (
          <p class="error-input-text">{errors.password.message}</p>
        )}
      </div>
      <div class="form-group">
        <label>Confirm Password </label>
        <input
          type="password"
          class="form-control"
          placeholder="Confirm Password"
          name="password2"
          ref={register}
          onBlur={() => {
            const pass = getValues("password");
            const passc = getValues("password2");
            if (pass !== passc) {
              setError("password2", {
                type: "manual",
                message: "Invalid Password confirmation",
              });
            }
          }}
          onFocus={() => {
            clearErrors(["password2"]);
          }}
          required
        ></input>
        {errors.password2 && (
          <p class="error-input-text">{errors.password2.message}</p>
        )}
      </div>
      <div class="form-group">
        <button type="submit" class="btn btn-primary">
          Register
        </button>
      </div>
      {children}
    </form>
  );
};

export default RegisterForm;

/*<form onSubmit={handleSubmit(onSubmit, onError)}>
      <input name="firstName" ref={register} />
      <input name="lastName" ref={register} />
      <button type="submit">Submit</button>
  </form>
  
  <Link className="btn btn-primary" ></Link>
        <Link className="btn btn-secondary ml-2" onClick={this.toggle} >Cancel</Link>*/
