import React from "react";
import { useForm } from "react-hook-form";
import { usersActions } from '../../actions';
import {useDispatch} from 'react-redux'
import { ToastContainer, toast } from 'react-toastify';

const LoginForm = ({children,onFinish}) =>{
  const { register, handleSubmit ,setError, errors ,getValues,clearErrors } = useForm();
  const dispatch = useDispatch()
  const onSubmit = (data, e) => {
        
        const {email, password} = getValues()
        let err = false;
        if(password.length < 6){
            setError("password", {
                type: "manual",
                message: "password is too short, must be at least 6 characters"
              });
              err = true
        }

        if(err) return

      dispatch(usersActions.login(data))
            .then(()=>{
                toast.success("login Succeeded",{autoClose: 4000});
                onFinish()
            })
            .catch(err => {
                const message = (err?.statusCode === 244) ? err.message : 'Internal error'
                toast.warning(message,{autoClose: 5000})
            })
      
  }
  const onError = (errors, e) => console.log(errors, e);

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
        <div class="form-group">
            <label>Email address</label>
            <input type="email" class="form-control" placeholder="Enter email" name='email' ref={register} required></input>
        </div>
        <div class="form-group">
            <label>Password </label>
            <input type="password" class="form-control" placeholder="Password" name='password' ref={register}
            onBlur={() => {
                const pass = getValues('password')
                if(pass.length < 6){
                    setError("password", {
                        type: "manual",
                        message: "password is too short, must be at least 6 characters"
                      });
                    }
                }
                }
            onFocus={()=>{
                clearErrors(["password"])
            }} required></input>
            {errors.password && <p class="error-input-text">{errors.password.message}</p>}
        </div>
        <div class="form-group">
            <button type="submit" class="btn btn-primary">Login</button>
        </div>
            {children}
    </form>
  );
}

export default LoginForm

