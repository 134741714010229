/**
 *  Admin Dashboard Menu
 */
import React, { Component, Fragment } from 'react';
import { Route } from 'react-router-dom';
import PageNotFound from '../../component/Pages/PageNotFound';
import Footer from '../../layouts/footer/Footer';
import Headers from '../../layouts/header/Header';
import { connect } from 'react-redux';
import { usersActions } from '../../actions';

// Component
import MarchantHeader from './MarchantHeader';
import MarchantProductList from './MarchantProductList';
import Productadd from './Product/Productadd';
import Productedit from './Product/Productedit';
import Login from './Login';

class MarchantDashboard extends Component {
  IsMarchantPage(pathname) {
    let pathArray = pathname.split('/');
    let productEditPage = pathArray[pathArray.length - 2];
    let pageName = pathArray[pathArray.length - 1];
    if (productEditPage == 'Product-edit') {
      return true;
    } else {
      if (
        pageName == 'marchant-panel' ||
        pageName == 'Product' ||
        pageName == 'product-add' ||
        pageName == 'Product-edit'
      )
        return true;
      else return false;
    }
  }

  render() {
    const { location } = this.props;
    const { match } = this.props;
    const { user } = this.props;
    let isLogin = user.expiresAt && user.expiresAt > new Date().getTime() / 1000;

    return (
      <>
        {!isLogin ? (
          <Fragment>
            <Route path={`${match.url}`} component={Login} />
          </Fragment>
        ) : (
          <>
            {(user.companyAdmin || user.companyAdmin == 'true') && isLogin ? (
              <Fragment>
                {this.IsMarchantPage(location.pathname) ? (
                  <MarchantHeader>
                    <Route path={`${match.url}`} exact component={MarchantProductList} />
                    <Route path={`${match.url}/Product`} component={MarchantProductList} />
                    <Route path={`${match.url}/product-add`} component={Productadd} />
                    <Route path={`${match.url}/Product-edit/:id`} component={Productedit} />
                  </MarchantHeader>
                ) : (
                  <div>
                    <Headers />
                    <Route exact component={PageNotFound} />
                    <Footer />
                  </div>
                )}
              </Fragment>
            ) : (
              <Route exact component={PageNotFound} />
            )}
          </>
        )}
      </>
    );
  }
}

const userStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const userDispatchToProps = (dispatch) => {
  return {
    logout: () => {
      dispatch({ type: usersActions.LOGOUT });
    },
  };
};

export default connect(userStateToProps, userDispatchToProps)(MarchantDashboard);
