/**
 *  Account Profile
 */
import React, { useEffect } from "react";
import { Link, Redirect } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { useSelector, shallowEqual } from "react-redux";
import Login from "../Forms/Login";

const LoginAdmin = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { isAdmin, user } = useSelector((state) => {
    return { isAdmin: state.user.isAdmin, user: state.user.info };
  }, shallowEqual);

  if (isAdmin == "true") return <Redirect to="/admin-panel/Coupons" />;
  else {
    return (
      <div>
        <div className="inner-intro">
          <Container>
            <Row className="intro-title align-items-center">
              <Col md={6} className="text-left">
                <div className="intro-title-inner">
                  <h1>Login</h1>
                </div>
              </Col>
              <Col md={6} className="text-right">
                <ul className="ciyashop_breadcrumbs page-breadcrumb breadcrumbs">
                  <li className="home">
                    <span>
                      <Link className="bread-link bread-home" to="/">
                        Home
                      </Link>
                    </span>
                  </li>
                  <li>
                    <span>Login</span>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="section-ptb">
          <Container>
            {!user && (
              <Row className="align-items-center">
                <Col lg={4} className="mt-4 mt-lg-0">
                  <Login></Login>
                </Col>
              </Row>
            )}
            <Row className="align-items-center">
              {user && (
                <p class="error-input-text">
                  You are not Admin, <Link to="/">click to Home Page</Link>
                </p>
              )}
            </Row>
          </Container>
        </div>
      </div>
    );
  }
};
export default LoginAdmin;
