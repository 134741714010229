import React from 'react';
import { PayPalButton } from './lib';
//console.log(process.env.REACT_APP_payPay_ClientId, 'process.env.REACT_APP_payPay_ClientId');
class PayPalBtn extends React.Component {
  render() {
    const { amount, onSuccess, currency, visible = false } = this.props;

    return  <div style={{display:`${visible?'block':'none'}`}}><PayPalButton
          visible={visible}
            amount={amount}
            currency={currency}
            onSuccess={(details, data) => onSuccess(details, data)}
            onError={(err) => console.log(err, 'error paypal')}
            options={{
              clientId: process.env.REACT_APP_payPay_ClientId,
              currency,  
              // 'disable-funding':'card'
            }}
          /></div>
  }
}
export default PayPalBtn;
